import Example from "./example.tsx";

import ParentSize from "@visx/responsive/lib/components/ParentSize";
import Family from "./views/Family/Family.tsx";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Homepage from "./views/Homepage/Homepage.tsx";
import { GlobalStyles } from "@mui/material";
import useWindowDimensions from "./hooks/useWindowDimensions.js";
import { useEffect } from "react";
import { image_urls } from "./utils/constants.js";
import { preloadImage } from "./utils/helpers.js";

function App() {
  const { height, width } = useWindowDimensions(); 

  useEffect(() => {
    // const loa = async () => {
    //   const images =  image_urls;
    //   for (var i = 0; i < images.length; i++) {
    //     await preloadImage(images[i]);
    //   }
    //   // setLoaded(true);
    // };
    // loa();
  }, []);
  return (
    <div style={{ height: "100dvh", width: "100dvw" }}>
      <BrowserRouter>
        <GlobalStyles
          styles={{
            scrollbarColor: "#6b6b6b #2b2b2b",
            "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
              backgroundColor: "#d3d3d3", //scroll box color
              borderRadius: 8,
            },
            "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
              borderRadius: 8,
              minHeight: 24,
              backgroundColor: "#959595", //scroll bar color

              border: "2px solid #959595",
            },
            "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus":
              {
                backgroundColor: "#959595",
              },
            "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active":
              {
                backgroundColor: "#959595",
              },
            "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover":
              {
                border: "1px solid #959500",
                backgroundColor: "#959595", //scroll bar color when hovered
              },
            "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
              backgroundColor: "#2b2b2b",
            },
          }}
        />
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/family" element={<Family mobile={width<800} />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
