import React, { useEffect, useState } from "react";
import Example from "../../example.tsx";
import ParentSize from "@visx/responsive/lib/components/ParentSize";
import Header from "../../components/Header/Header.tsx";
import { data } from "../../utils/family.ts";
import Popup from "../../components/Popup/Popup.jsx";
import { getAncestors, getNextImage, getPersonById } from "../../utils/helpers.js";
import { image_urls } from "../../utils/constants.js";
// make the parent width 90dvh for mobile and height for desktop
function Family({ mobile }) { 
  const [genealogy, setGenealogy] = useState([
    { id: data.id, name: data.firstName },
  ]);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [selectedPerson, setSelectedPerson] = useState(person);
  const [selectedTrunk, setSelectedTrunk] = useState(data);

  const [openTrunk, setOpenTrunk] = useState<any>({ 0: "1" });


  const handleChnageOpenDepth = (depth: Number, id: string) => {
    // look for the persone with the id
    // look for parent
    // look for grand parent if exist
    // set chosen person as a starting data
    // update header component with current path using parent and grand parent
  };

  const handleBackButtonPressd = () => {
    if (mobile && genealogy.length>1) {
      var copyOfGenealogy = genealogy 
      copyOfGenealogy.pop()
      // get key of openTrunk by selectedTrunk and remove value

      setOpenTrunk({0:genealogy[genealogy.length-1].id})
      setSelectedTrunk(getPersonById([data], genealogy[genealogy.length-1].id))
      setGenealogy(copyOfGenealogy)

      // genealogy.map((gen)=>{

      // })
      setCurrentBg(image_urls[getNextImage(currentBg, image_urls)])
     console.log(openTrunk)
    }
  };

  useEffect(() => {

    setSelectedTrunk(data)
    setOpenTrunk({ 0: "1" })
    setGenealogy([
      { id: data.id, name: data.firstName },
    ]);
  }, [mobile]);
  const [currentBg, setCurrentBg] = useState(image_urls[0])
  return (
    <div className="--flex-center gap-big">
      <Popup
        isOpen={isPopupOpen}
        onClose={() => setIsPopupOpen(false)}
        person={selectedPerson}
      ></Popup>
      <div
        className="dim-background"
        style={{
          // backgroundImage: `url(${currentBg})`,
          backgroundImage: `url(https://res.cloudinary.com/dtknehocq/image/upload/v1707039191/Moti%20yidne%20family%20tree/sm_sz-01-01-01_hk6dh6.jpg)`,
        }}
      ></div>
      <Header genealogy={genealogy} handleBackPressed={handleBackButtonPressd}/>
      <div className="parent-size-parent"style={{ width: "90dvw", height:mobile? "95dvh":"85dvh" }}>
        <ParentSize>
          {({ width, height }) => (
            <Example
              width={width}
              height={height}
              mobile={mobile}
              onClickPerson={() => {
                setIsPopupOpen(true);
              }}
              setSelectedPerson={(person) => setSelectedPerson(person)}
              setSelectedTrunk={(person) => {
                setSelectedTrunk(person); 
      setCurrentBg(image_urls[getNextImage(currentBg, image_urls)])

                setGenealogy([
                  ...genealogy,
                  { id: person.id, name: person.firstName },
                ]);
              }}
              selectedTrunk={selectedTrunk}

              openTrunk={openTrunk} setOpenTrunk={(trunk)=>setOpenTrunk(trunk)}
            />
          )}
        </ParentSize>
      </div>
    </div>
  );
}

export default Family;

const person = {
  nickname: `Baby`,
  firstName: "Samuel",
  lastName: "Petros",
  image:
    "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?q=80&w=3648&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
  nameMeaning: "The Name of God",

  title: "Mr.",

  id: "1.01",

  children: 12,

  birthDate: "12/12/12",
  deathDate: "12/12/12",

  father: "Petros",
  mother: "Ruth",

  spouses: [
    {
      nickname: `Baby`,
      firstName: "Samuel",
      lastName: "Petros",

      image:
        "https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?q=80&w=3648&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      nameMeaning: "The Name of God",

      title: "Mr.",

      id: "1.01",

      children: 12,

      birthDate: "12/12/12",
      deathDate: "12/12/12",

      father: "Petros",
      mother: "Ruth",
    },
  ],
};
