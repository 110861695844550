import React, { useEffect, useLayoutEffect, useState } from "react";
import { Group } from "@visx/group";
import { hierarchy, Tree } from "@visx/hierarchy";
import { LinearGradient } from "@visx/gradient";
import { pointRadial } from "d3-shape";
import useForceUpdate from "./useForceUpdate.ts";
import LinkControls from "./LinkControls.tsx";
import getLinkComponent from "./getLinkComponent.ts";
import { getQueryVariable } from "./utils/helpers.js";
import { data } from "./utils/family.ts";
import useLongPress from "./hooks/useLongPress.ts";

// interface TreeNode {
//   name: string;
//   id: string;
//   isExpanded?: boolean;
//   children?: TreeNode[];
// }

// const data: TreeNode = {
//   id: "1",
//   name: "Ayano Benti",
//   children: [
//     {
//       id: "1.01",
//       name: "B",
//       children: [
//         {
//           id: "1.01.01",
//           name: "B",
//           children: [
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1.01.02",
//           name: "A1",
//           children: [
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1.01.03",
//           name: "A1",
//           children: [
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1.01.04",
//           name: "A1",
//           children: [
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1.01.05",
//           name: "A1",
//           children: [
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1.01.06",
//           name: "A1",
//           children: [
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1.01.07",
//           name: "A1",
//           children: [
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1.01.08",
//           name: "A1",
//           children: [
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1.01.09",
//           name: "A1",
//           children: [
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1.01.10",
//           name: "A1",
//           children: [
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1.01.11",
//           name: "A1",
//           children: [
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//           ],
//         },
//       ],
//     },
//     {
//       id: "1.02",
//       name: "A",
//       children: [
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//           ],
//         },
//       ],
//     },
//     {
//       id: "1.03",
//       name: "A",
//       children: [
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//           ],
//         },
//       ],
//     },
//     {
//       id: "1.04",
//       name: "A",
//       children: [
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//           ],
//         },
//       ],
//     },
//     {
//       id: "1.05",
//       name: "A",
//       children: [
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//           ],
//         },
//       ],
//     },
//     {
//       id: "1.06",
//       name: "A",
//       children: [
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//           ],
//         },
//       ],
//     },
//     {
//       id: "1.07",
//       name: "A",
//       children: [
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//           ],
//         },
//       ],
//     },
//     {
//       id: "1.08",
//       name: "A",
//       children: [
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//           ],
//         },
//       ],
//     },
//     {
//       id: "1.09",
//       name: "A",
//       children: [
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//           ],
//         },
//       ],
//     },
//     {
//       id: "1.1",
//       name: "A",
//       children: [
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//           ],
//         },
//       ],
//     },
//   ],
// };

const defaultMargin = { top: 30, left: 30, right: 30, bottom: 70 };

export type LinkTypesProps = {
  width: number;
  height: number;
  margin?: { top: number; right: number; bottom: number; left: number };
  mobile: boolean;
  onClickPerson: Function;
  setSelectedPerson: Function;
  selectedTrunk: any;
  setSelectedTrunk: Function;
  openTrunk: any;
  setOpenTrunk: Function;
};

export default function Example({
  width: totalWidth,
  height: totalHeight,
  margin = defaultMargin,
  mobile: mobile,
  onClickPerson: onClickperson,
  setSelectedPerson: setSelectedPerson,
  selectedTrunk: selectedTrunk,
  setSelectedTrunk: setSelectedTrunk,
  openTrunk: openTrunk,
  setOpenTrunk: setOpenTrunk,
}: LinkTypesProps) {
  const [layout, setLayout] = useState<string>("cartesian");
  const [orientation, setOrientation] = useState<string>("vertical");
  const [linkType, setLinkType] = useState<string>("diagonal");
  const [stepPercent, setStepPercent] = useState<number>(0.5);
  const forceUpdate = useForceUpdate();
  const [familyId, setFamilyId] = useState<string | null | boolean>(null);
  const [closeAll, setCloseAll] = useState(true);
  // const [openTrunk, setOpenTrunk] = useState<any>({ 0: "1" });
  const [isLongPressed, setIsLongPressed] = useState(false);

  const innerWidth = totalWidth - margin.left - margin.right;
  const innerHeight = totalHeight - margin.top - margin.bottom;

  let origin: { x: number; y: number };
  let sizeWidth: number;
  let sizeHeight: number;

  if (layout === "polar") {
    origin = {
      x: innerWidth / 2,
      y: innerHeight / 2,
    };
    sizeWidth = 2 * Math.PI;
    sizeHeight = Math.min(innerWidth, innerHeight) / 2;
  } else {
    origin = { x: 0, y: 0 };
    if (orientation === "vertical") {
      sizeWidth = innerWidth;
      sizeHeight = innerHeight;
    } else {
      sizeWidth = innerHeight;
      sizeHeight = innerWidth;
    }
  }

  const LinkComponent = getLinkComponent({ layout, linkType, orientation });
  // GET FAMILY ID
  useLayoutEffect(() => {
    if (mobile) {
      setOrientation("horizontal");
    }

    setFamilyId(getQueryVariable("familyId"));
    // const myMap = new Map();
    // myMap.set(0, "1");
    // setOpenTrunk(myMap);
  }, []);
  useEffect(() => {
    if (mobile) {
      setOrientation("horizontal");
    } else {
      setOrientation("vertical");
    }
  }, [mobile]);

  // // LONG PRESS STUFF
  // const onLongPress = () => {
  //   console.log("longpress is triggered");
  //   setIsLongPressed(true);
  // };

  // const onClick = (e) => {
  //   console.log("click is triggered");
  // };

  // const defaultOptions = {
  //   shouldPreventDefault: true,
  //   delay: 500,
  // };
  // const longPressEvent = useLongPress({ onLongPress, onClick });

  const [waitingClick, setWaitingClick] = useState<any>(null);
  const [lastClick, setLastClick] = useState(0);
  const processClick = (e, node, forceUpdate) => {
    if (lastClick && e.timeStamp - lastClick < 250 && waitingClick) {
      setLastClick(0);
      clearTimeout(waitingClick);
      setWaitingClick(null);
      // console.log("double click");
      if (
        node.data.children?.length &&
        !(selectedTrunk.id == node.data.id && mobile)
      ) {
        if (openTrunk[node.depth] === node.data.id) {
          var openTrunkCoppy = openTrunk;
          openTrunkCoppy[node.depth] = "";
          setOpenTrunk(openTrunkCoppy);
        } else {
          var openTrunkCoppy = openTrunk;
          openTrunkCoppy[node.depth] = node.data.id;
          setOpenTrunk(openTrunkCoppy);
        }
        if (mobile) {
          setSelectedTrunk(node.data);
        }
        forceUpdate();
      }
      // console.log(openTrunk);
      if (!node.data.children?.length) {
        // show snake bar that says not branch end
      }
    } else {
      setLastClick(e.timeStamp);
      setWaitingClick(
        setTimeout(() => {
          setWaitingClick(null);
          // console.log("single click");
          setSelectedPerson(node.data);
          onClickperson();
        }, 251)
      );
    }
  };
  return totalWidth < 10 || !familyId ? null : (
    <div className="family-tree-background">
      <LinkControls
        layout={layout}
        orientation={orientation}
        linkType={linkType}
        stepPercent={stepPercent}
        setLayout={setLayout}
        setOrientation={setOrientation}
        setLinkType={setLinkType}
        setStepPercent={setStepPercent}
      />
      <svg width={totalWidth} height={totalHeight + 35} overflow="visible">
        <LinearGradient id="links-gradient" from="#fd9b93" to="#fe6e9e" />
        <rect
          width={totalWidth}
          height={totalHeight}
          rx={14}
          fill="#4d462700"
        />
        <Group top={margin.top} left={margin.left}>
          <Tree
            root={hierarchy(selectedTrunk, (d) =>
              d.isExpanded ||
              Array.from(Object.values(openTrunk)).indexOf(d.id) == -1
                ? null
                : d.children
            )}
            size={[sizeWidth, sizeHeight]}
            separation={(a, b) => (a.parent === b.parent ? 1000 : 0.5)} // /a.depth }
          >
            {(tree) => (
              <Group top={origin.y} left={origin.x}>
                {tree.links().map((link, i) => (
                  <LinkComponent
                    key={i}
                    data={link}
                    percent={stepPercent}
                    stroke="#fff"
                    strokeWidth="1"
                    fill="none"
                  />
                ))}

                {tree.descendants().map((node, key) => {
                  const width = 20;
                  const height = 20;

                  let top: number;
                  let left: number;
                  if (layout === "polar") {
                    const [radialX, radialY] = pointRadial(node.x, node.y);
                    top = radialY;
                    left = radialX;
                  } else if (orientation === "vertical") {
                    top = node.y;
                    left = node.x;
                  } else {
                    top = node.x;
                    left = node.y;
                  }

                  if (closeAll) {
                    // node.data.isExpanded = false
                    // forceUpdate();
                  }

                  return (
                    <Group top={top} left={left} key={key}>
                      {/* {node.depth === 0 && (
                        <circle
                          r={12}
                          fill="url('#links-gradient')"
                          onClick={() => {
                            node.data.isExpanded = !node.data.isExpanded;
                            console.log(node);
                            forceUpdate();
                          }}
                        />
                      )} */}
                      {/* {node.depth !== 0 && (
                        <rect
                          height={height}
                          width={width}
                          y={-height / 2}
                          x={-width / 2}
                          fill="#272b4d"
                          stroke={node.data.children ? "#03c0dc" : "#26deb0"}
                          strokeWidth={1}
                          strokeDasharray={node.data.children ? "0" : "2,2"}
                          strokeOpacity={node.data.children ? 1 : 0.6}
                          rx={node.data.children ? 0 : 10}
                          onClick={(e) => {
                       
                            processClick(e, node, forceUpdate);
                          }}
                           
                        />
                      )} */}

                      {/* {node.depth > 1 && ( */}
                      {/* <rect
                         y={!mobile || node.depth == 0 ? "3.33rem" : 0}
                         x={
                           !mobile || node.depth == 0
                             ? mobile && node.depth == 0
                               ? ".9rem"
                               : 0
                             : "-5.33rem"
                         }
                          height={height}
                          width={width}
                          
                          fill="#272b4d"
                          stroke={node.data.children ? "#03c0dc" : "#26deb0"}
                          strokeWidth={1}
                          strokeDasharray={node.data.children ? "0" : "2,2"}
                          strokeOpacity={node.data.children ? 1 : 0.6}
                          rx={node.data.children ? 0 : 10}
                          onClick={(e) => {
                            processClick(e, node, forceUpdate);
                          }}
                        /> */}
                      {/* )} */}
                      {/* {node.depth <= 1 && ( */}

                      <foreignObject
                        y={-height * 1.2}
                        x={
                          node.depth == 0 && !mobile ? -width * 2 : -width * 1.5
                        }
                        width="160"
                        height="160"
                      >
                        <div
                          className="avatar"
                          onClick={(e) => {
                            processClick(e, node, forceUpdate);
                          }}
                        >
                          <img
                          className={openTrunk[node.depth]==node.data.id?"opened-trunk-image":""}
                            height={height * 3}
                            width={width * 3}
                            style={{ borderRadius: "10px" }}
                            alt=""
                            src={
                              node.data.avatar!="n/a" && node.data.avatar
                              ? node.data.avatar
                              : "https://res.cloudinary.com/dtknehocq/image/upload/v1707027881/Moti%20yidne%20family%20tree/8380015_kytgkr.jpg"
                            }
                          />
                          <p className={openTrunk[node.depth]==node.data.id?"--no-padding avatar-name":"--no-padding avatar-name  opened-trunk-name"}>
                            {node.data.title !== "n/a" && node.data.title}
                            {" " + node.data.firstName}
                            <br />
                            {node.data.lastName}
                            <br />
                            {node.data.children?.length
                              ? " (" + node.data.children.length + ")"
                              : null}
                          </p>
                        </div>
                      </foreignObject>

                      {/* <image
                         
                       
                        height={height * 3}
                        width={width * 3}
                        y={-height * 1.2}
                        x={-width * 1.5}
                        href="https://images.unsplash.com/photo-1535713875002-d1d0cf377fde?q=80&w=3648&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                        clip-path="url(#circleView)"
                      /> */}
                      {/* )} */}
                      {/* {node.depth > 1 && ( */}

                      <text
                        className="mobile-avatar-name"
                        pointsAtX={0}
                        filter="url(#solid)"
                        dy={!mobile || node.depth == 0 ? "3.33rem" : 0}
                        dx={
                          !mobile || node.depth == 0
                            ? mobile && node.depth == 0
                              ? "-1.5rem"
                              : "-.7rem"
                            : "-3rem"
                        }
                        fontSize={16}
                        fontFamily="Arial"
                        textAnchor={node.depth == 0 ? "start" : "end"}
                        style={{
                          pointerEvents: "none",
                          backgroundColor: "blue",
                          textAlign: "center",
                        }}
                        fill={
                          // node.depth === 0
                          //   ? "#71248e"
                          //   : node.children
                          //   ? "white"
                          //   :
                          "#26deb0"
                        }
                      >
                        {node.data.firstName}
                        {node.data.children?.length
                          ? " (" + node.data.children.length + ")"
                          : null}
                      </text>
                      {/* )} */}
                    </Group>
                  );
                })}
              </Group>
            )}
          </Tree>
        </Group>
      </svg>
    </div>
  );
}
