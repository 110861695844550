import React from "react";

function Homepage() {
  return (
    <div className="--flex-center">
      {" "}
      <div
        className="dim-background"
        style={{
          backgroundImage: `url(https://images.unsplash.com/photo-1621502863666-e47e3bd2547b?q=80&w=3774&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D)`,
        }}
      ></div>
      <h1 color="white">Family Tree Coming Soon!</h1>
    </div>
  );
}

export default Homepage;
