import React from "react";
import "./Popup.css";
import { Dialog, DialogTitle } from "@mui/material";
import Slide from "@mui/material/Slide";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import { Close, CloseFullscreen, CloseSharp } from "@mui/icons-material";
// import { ReactComponent as Collaps } from "assets/svg/collapse_content_FILL0_wght400_GRAD0_opsz24.svg";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});
function Popup({ isOpen, onClose, person }) {
  return (
    <Dialog
      open={isOpen}
      // onClose={onClose}
      fullWidth
      TransitionComponent={Transition}
      maxWidth={"xl"}
      sx={{
        "& .MuiPaper-root": {
          background: "transparent",
          boxShadow: "none",
        },
        "& .MuiBackdrop-root": {
          backgroundColor: "#171717d5",
        },
      }}
    >
      <div className="person-detail-parent">
        <div
          className="person-detail-image"
          style={{
            backgroundImage: `url(${
             person.avatar!="n/a" && person.avatar
                ? person.avatar
                : "https://res.cloudinary.com/dtknehocq/image/upload/v1707027881/Moti%20yidne%20family%20tree/8380015_kytgkr.jpg"
            })`,
          }}
        ></div>
        <div className="person-detail-information">
          <h1 className="person-detial-info-full-name">
            {person.firstName} <span></span> {person.lastName}{" "}
            <span className="nickname">
              {person.nickname !== "n/a" ? `(` + person.nickname + `)` : null}
            </span>
          </h1>
          <hr />
          <p>
            {"Sex: "} {String(person.sex) == "true" ? "Male" : "Famele"}
          </p>
          <p>
            {" "}
            {String(person.birthDate)?.trim() != "n/a"
              ? "Date of birth: " + String(person.birthDate)
              : null}
          </p>

          <p>
            {" "}
            {String(person.nameMeaning)?.trim() != "n/a"
              ? "Name meaning: " + String(person.nameMeaning)
              : null}
          </p>
          <p>
            {" "}
            {String(person.father)?.trim() != "n/a"
              ? "Father's name: " + String(person.father)
              : null}
          </p>
          <p>
            {" "}
            {String(person.mother)?.trim() != "n/a"
              ? "Mother's name: " + String(person.mother)
              : null}
          </p>
          <p>
            {" "}
            {person.spouses?.length
              ? "Spouse/s: " +
                person.spouses.map((sp, index) => {
              
                  return   " "+ sp.firstName + " " + sp.lastName;
                  
                })
              : null}
          </p>
          <p>
            {" "}
            {person.children?.length != 0
              ? "Total children: " + String(person.children?.length)
              : null}
          </p>

          <p>
            {" "}
            {String(person.deathDate)?.trim() != "n/a" &&
            String(person.deathDate)?.trim()?.length > 3
              ? "Date of passing: " + String(person.deathDate)
              : null}
          </p>
          <p>
            {" "}
            {String(person.history)?.trim() != "n/a"
              ? "History: " + String(person.history)
              : null}
          </p>
          <p>
            {" "}
            {String(person.cementery)?.trim() != "n/a"
              ? "Cementary: " + String(person.cementery)
              : null}
          </p>
        </div>
      </div>

      <div className="close-icon" onClick={onClose}>
        <CloseSharp />
      </div>
      {/* <div className="dialog-parent">
        <div className="dialog-top">
            <div className="close-icon" onClick={onClose}>
              <Close
                style={{
                  filter: `invert(100%) sepia(100%) saturate(0%) hue-rotate(298deg) brightness(102%) contrast(102%)`,
                }}
              />
            </div>
            <DialogTitle>{title}</DialogTitle>
          </div>
        <div className="dialog-sub-parent">
          
          {children}
          {paragraph.map((body) => (
            <p className="detail-paragraph">{body.text}</p>
          ))}
          <p className="detail-paragraph">
          {lastComponent}</p>
        </div>
      </div> */}
    </Dialog>
  );
}

export default Popup;
