export function getQueryVariable(variable) {
  var query = window.location.search.substring(1);
  var vars = query.split("&amp;");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (pair[0] == variable) {
      return pair[1];
    }
  }
  return false;
}

export function getAncestors(family, currentId, cut) {
  var ancestors = [{ id: family.id, name: family.name }];
  console.log("current id", currentId);

  const listOfIds = currentId.split(".");

  listOfIds.splice(0, cut);
  if (listOfIds.length > 0 && family.children) {
    family.children?.map((child) => {
      if (child.id == currentId.split(".").splice(cut).join(".")) {
        ancestors = [...ancestors, ...getAncestors(child, listOfIds.join("."))];
        console.log("remaining trunk", listOfIds.join("."));
      }
    });
  }

  return ancestors;
}

export function getPersonById(o, id) {
  let i;
  for (i of o) {
    if(i.id==id){
      return i;
    } else if(i.children?.length>0){
      const inComponents = getPersonById(i.children,id);
      if (typeof(inComponents) != "undefined") return inComponents;
    }
  }
}

export const preloadImage = async (src) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.src = src;
    window[src] = image;
    image.style.display = "none";
    image.onload = () => {
      document.body.removeChild(image);
      resolve();
    };
    image.onerror = reject;
    document.body.appendChild(image);
  });
 export const getNextImage = (current, list)=>{
  const now = list.indexOf(current)
  if(now < 4){
  console.log(now, "to",now+1)

    return now+1
  }else{
  console.log(now, "to",0)
    
    return 0
  }
 }
