// interface TreeNode {
//   name: string;
//   firstName?: string;
//   lastName?: string;

//   nameMeaning?:string;

//   title?: string;

//   id: string;

//   isExpanded?: boolean;
//   children?: TreeNode[];

//   birthDate?:string;
//   deathDate?:string;

//   father?:string;
//   mother?:string;

//   spouses?:Spouse[];
// }
// interface Spouse {
//   nickname: string;
//   firstName?: string;
//   lastName?: string;

//   nameMeaning?:string;

//   title?: string;

//   id: string;

//   isExpanded?: boolean;
//   children?: TreeNode[];

//   birthDate?:string;
//   deathDate?:string;

//   father?:string;
//   mother?:string; 
// }

// export const data: TreeNode =
//  {
//   id: "1",
//   name: "Ayano Benti",
//   children: [
//     {
//       id: "1.01",
//       name: "Chaltu",
//       children: [
//         {
//           id: "1.01.01",
//           name: "Samuel",
//           children: [
//             { id: "1", name: "Samuel  ", children:[] },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1.01.02",
//           name: "A1",
//           children: [
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//             { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1.01.03",
//           name: "A1",
//           children: [
//             { id: "1", name: "Teklehaimanot" },
//             { id: "1", name: "Teklehaimanot" },
//             { id: "1", name: "Teklehaimanot" },
//             { id: "1", name: "Teklehaimanot" },
//             { id: "1", name: "Teklehaimanot" },
//             { id: "1", name: "Teklehaimanot" },
//             { id: "1", name: "Teklehaimanot" },
//             { id: "1", name: "Teklehaimanot" },
//             { id: "1", name: "Teklehaimanot" },
//             { id: "1", name: "Teklehaimanot" },
 
//           ],
//         },
//         {
//           id: "1.01.04",
//           name: "A1",
//           children: [
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1.01.05",
//           name: "A1",
//           children: [
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1.01.06",
//           name: "A1",
//           children: [
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1.01.07",
//           name: "A1",
//           children: [
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1.01.08",
//           name: "A1",
//           children: [
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1.01.09",
//           name: "A1",
//           children: [
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1.01.10",
//           name: "A1",
//           children: [
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1.01.11",
//           name: "A1",
//           children: [
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//           ],
//         },
//       ],
//     },
//     {
//       id: "1.02",
//       name: "Chaltu",
//       children: [
//         {
//           id: "1.02.01",
//           name: "A1",
//           children: [
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1.02.02",
//           name: "A1",
//           children: [
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1.02.03",
//           name: "A1",
//           children: [
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1.02.04",
//           name: "A1",
//           children: [
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1.02.05",
//           name: "A1",
//           children: [
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1.02.06",
//           name: "A1",
//           children: [
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1.02.07",
//           name: "A1",
//           children: [
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1.02.08",
//           name: "A1",
//           children: [
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1.02.09",
//           name: "A1",
//           children: [
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1.02.10",
//           name: "A1",
//           children: [
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1.02.11",
//           name: "A1",
//           children: [
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//           ],
//         },
//       ],
//     },
//     {
//       id: "1.03",
//       name: "Chaltu",
//       children: [
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//           ],
//         },
//       ],
//     },
//     {
//       id: "1.04",
//       name: "Chaltu",
//       children: [
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//           ],
//         },
//       ],
//     },
//     {
//       id: "1.05",
//       name: "Chaltu",
//       children: [
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//           ],
//         },
//       ],
//     },
//     {
//       id: "1.06",
//       name: "Chaltu",
//       children: [
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//           ],
//         },
//       ],
//     },
//     {
//       id: "1.07",
//       name: "Chaltu",
//       children: [
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//           ],
//         },
//       ],
//     },
//     {
//       id: "1.08",
//       name: "Chaltu",
//       children: [
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//           ],
//         },
//       ],
//     },
//     {
//       id: "1.09",
//       name: "Chaltu",
//       children: [
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//           ],
//         },
//       ],
//     },
//     {
//       id: "1.1",
//       name: "Chaltu",
//       children: [
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//           ],
//         },
//         {
//           id: "1",
//           name: "A1",
//           children: [
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//             // { id: "1", name: "A1" },
//           ],
//         },
//       ],
//     },
//   ],
// };

interface TreeNode {
  nickname: string;
  // name: string;
  avatar:string;
  firstName?: string;
  lastName?: string;

  nameMeaning?:string;

  title?: string;

  id: string;

  isExpanded?: boolean;
  children?: TreeNode[];
  sex?:any;

  birthDate?:string;
  deathDate?:string;

  father?:string;
  mother?:string;

  spouses?:Spouse[];
  deathCause:string;
  deathcause?:string;
  cementery:string;
  history:string;
}
interface Spouse {
  nickname: string;
  avatar:string;
  firstName?: string;
  lastName?: string;

  nameMeaning?:string;

  title?: string;
  spouses?:Spouse[];

  id: string;
  sex?:any;
  isExpanded?: boolean;
  children?: TreeNode[];

  birthDate?:string;
  deathDate?:string;

  father?:string;
  mother?:string; 
  deathCause:string;
  cementery:string;
  history:string;
}

// export const data: TreeNode =
// {
//   "id": "1",
//   "nickname": "n/a",
//   "firstName": "Ayano",
//   "lastName": "Benti",
//   "nameMeaning": "n/a",
//   "title": "Mr.",
//   "sex": true,
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "n/a",
//   "mother": "n/a",
//   "spouses": [],
//   "children": [
//   {
//   "id": "1.01",
//   "nickname": "n/a",
//   "firstName": "Dara",
//   "lastName": "Ayano",
//   "nameMeaning": "n/a",
//   "title": "Mrs.",
//   "sex": false,
//   "children": [
//   {
//   "id": "1.01.01",
//   "nickname": "n/a",
//   "firstName": "Gette",
//   "lastName": "Hunde",
//   "nameMeaning": "n/a",
//   "title": "Ms.",
//   "sex": "false",
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "Hunde Waqeyo",
//   "mother": "Dara Ayano",
//   "spouses": []
//   },
//   {
//   "id": "1.01.02",
//   "nickname": "n/a",
//   "firstName": "Worku",
//   "lastName": "Hunde",
//   "nameMeaning": "n/a",
//   "title": "Mr.",
//   "sex": true,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "Hunde Waqeyo",
//   "mother": "Dara Ayano",
//   "spouses": []
//   },
//   {
//   "id": "1.01.03",
//   "nickname": "n/a",
//   "firstName": "Geremu",
//   "lastName": "Tadese",
//   "nameMeaning": "n/a",
//   "title": "Mrs.",
//   "sex": false,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "Tadese Adicho",
//   "mother": "Dara Ayano",
//   "spouses": []
//   },
//   {
//   "id": "1.01.04",
//   "nickname": "n/a",
//   "firstName": "Belayitu",
//   "lastName": "Tadese",
//   "nameMeaning": "n/a",
//   "title": "Mrs.",
//   "sex": false,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "Tadese Adicho",
//   "mother": "Dara Ayano",
//   "spouses": []
//   },
//   {
//   "id": "1.01.05",
//   "nickname": "n/a",
//   "firstName": "Tsehay",
//   "lastName": "Tadese",
//   "nameMeaning": "n/a",
//   "title": "Mrs.",
//   "sex": false,
//   "children": [
//   {
//   "id": "1.01.05.01",
//   "nickname": "n/a",
//   "firstName": "Helen",
//   "lastName": "Hailu",
//   "nameMeaning": "n/a",
//   "title": "Mrs.",
//   "sex": false,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "Hailu",
//   "mother": "Tsehay Tadese",
//   "spouses": []
//   },
//   {
//   "id": "1.01.05.02",
//   "nickname": "n/a",
//   "firstName": "Meskerem",
//   "lastName": "Hailu",
//   "nameMeaning": "n/a",
//   "title": "Mrs.",
//   "sex": false,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "Hailu",
//   "mother": "Tsehay Tadese",
//   "spouses": []
//   },
//   {
//   "id": "1.01.05.03",
//   "nickname": "n/a",
//   "firstName": "Dinqalem",
//   "lastName": "Hailu",
//   "nameMeaning": "n/a",
//   "title": "Mrs.",
//   "sex": false,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "Hailu",
//   "mother": "Tsehay Tadese",
//   "spouses": []
//   },
//   {
//   "id": "1.01.05.04",
//   "nickname": "n/a",
//   "firstName": "Meleshiw",
//   "lastName": "Hailu",
//   "nameMeaning": "n/a",
//   "title": "Mrs.",
//   "sex": false,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "Hailu",
//   "mother": "Tsehay Tadese",
//   "spouses": []
//   },
//   {
//   "id": "1.01.05.05",
//   "nickname": "n/a",
//   "firstName": "Anuma",
//   "lastName": "Hailu",
//   "nameMeaning": "n/a",
//   "title": "n/a",
//   "sex": "n/a",
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "Hailu",
//   "mother": "Tsehay Tadese",
//   "spouses": []
//   },
//   {
//   "id": "1.01.05.06",
//   "nickname": "n/a",
//   "firstName": "Fikir",
//   "lastName": "Hailu",
//   "nameMeaning": "n/a",
//   "title": "n/a",
//   "sex": "n/a",
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "Hailu",
//   "mother": "Tsehay Tadese",
//   "spouses": []
//   }
//   ],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "Tadese Adicho",
//   "mother": "Dara Ayano",
//   "spouses": []
//   },
//   {
//   "id": "1.01.06",
//   "nickname": "n/a",
//   "firstName": "Jemal",
//   "lastName": "Tadese",
//   "nameMeaning": "n/a",
//   "title": "Mr.",
//   "sex": true,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "n/a",
//   "mother": "n/a",
//   "spouses": []
//   },
//   {
//   "id": "1.01.07",
//   "nickname": "n/a",
//   "firstName": "Tirfinesh",
//   "lastName": "Tadese",
//   "nameMeaning": "n/a",
//   "title": "Mrs.",
//   "sex": false,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "Tadese Adicho",
//   "mother": "Dara Ayano",
//   "spouses": []
//   },
//   {
//   "id": "1.01.08",
//   "nickname": "n/a",
//   "firstName": "Mulu",
//   "lastName": "Sebeko",
//   "nameMeaning": "n/a",
//   "title": "Mrs.",
//   "sex": "n/a",
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "Tadese Adicho",
//   "mother": "Dara Ayano",
//   "spouses": []
//   }
//   ],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "Ayano Benti",
//   "mother": "n/a",
//   "spouses": [
//   {
//   "id": "1.01.a",
//   "nickname": "n/a",
//   "firstName": "Hunde",
//   "lastName": "Waqeyo",
//   "nameMeaning": "n/a",
//   "title": "Mr.",
//   "sex": "true",
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "n/a",
//   "mother": "n/a"
//   },
//   {
//     "id": "1.01.b",
//   "nickname": "n/a",
//   "firstName": "Tadese",
//   "lastName": "Adicho",
//   "nameMeaning": "n/a",
//   "title": "Mr.",
//   "sex": "true",
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "n/a",
//   "mother": "n/a"
//   }
//   ]
//   },
//   {
//   "id": "1.02",
//   "nickname": "Werq",
//   "firstName": "Meti",
//   "lastName": "Ayano",
//   "nameMeaning": "n/a",
//   "title": "Mrs.",
//   "sex": false,
//   "children": [
//   {
//   "id": "1.02.01",
//   "nickname": "n/a",
//   "firstName": "Irba",
//   "lastName": "Ordofa",
//   "nameMeaning": "n/a",
//   "title": "Mrs.",
//   "sex": false,
//   "children": [
//   {
//   "id": "1.02.01.01",
//   "nickname": "n/a",
//   "firstName": "Gelane",
//   "lastName": "Leta",
//   "nameMeaning": "n/a",
//   "title": "Mrs.",
//   "sex": false,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "Leta",
//   "mother": "Irba Ordofa",
//   "spouses": []
//   },
//   {
//   "id": "1.02.01.02",
//   "nickname": "n/a",
//   "firstName": "Ergo",
//   "lastName": "Leta",
//   "nameMeaning": "n/a",
//   "title": "Mrs.",
//   "sex": false,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "Leta",
//   "mother": "Irba Ordofa",
//   "spouses": []
//   },
//   {
//   "id": "1.02.01.03",
//   "nickname": "n/a",
//   "firstName": "Bonsa",
//   "lastName": "Leta",
//   "nameMeaning": "n/a",
//   "title": "Lij",
//   "sex": "n/a",
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "Leta",
//   "mother": "Irba Ordofa",
//   "spouses": []
//   },
//   {
//   "id": "1.02.01.04",
//   "nickname": "n/a",
//   "firstName": "Fikru",
//   "lastName": "Leta",
//   "nameMeaning": "n/a",
//   "title": "Mrs.",
//   "sex": false,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "Leta",
//   "mother": "Irba Ordofa",
//   "spouses": []
//   },
//   {
//   "id": "1.02.01.05",
//   "nickname": "n/a",
//   "firstName": "Meri",
//   "lastName": "Leta",
//   "nameMeaning": "n/a",
//   "title": "Mrs.",
//   "sex": false,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "Leta",
//   "mother": "Irba Ordofa",
//   "spouses": []
//   },
//   {
//   "id": "1.02.01.06",
//   "nickname": "n/a",
//   "firstName": "Tejitu",
//   "lastName": "Leta",
//   "nameMeaning": "n/a",
//   "title": "Mrs.",
//   "sex": false,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "Leta",
//   "mother": "Irba Ordofa",
//   "spouses": []
//   },
//   {
//   "id": "1.02.01.07",
//   "nickname": "n/a",
//   "firstName": "Kuma",
//   "lastName": "Leta",
//   "nameMeaning": "n/a",
//   "title": "Mr.",
//   "sex": true,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "Leta",
//   "mother": "Irba Ordofa",
//   "spouses": []
//   },
//   {
//   "id": "1.02.01.08",
//   "nickname": "n/a",
//   "firstName": "Kibitu",
//   "lastName": "Leta",
//   "nameMeaning": "n/a",
//   "title": "Mrs.",
//   "sex": false,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "Leta",
//   "mother": "Irba Ordofa",
//   "spouses": []
//   },
//   {
//   "id": "1.02.01.09",
//   "nickname": "n/a",
//   "firstName": "Urge",
//   "lastName": "Leta",
//   "nameMeaning": "n/a",
//   "title": "Mrs.",
//   "sex": false,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "Leta",
//   "mother": "Irba Ordofa",
//   "spouses": []
//   },
//   {
//   "id": "1.02.01.10",
//   "nickname": "n/a",
//   "firstName": "Asefa",
//   "lastName": "Leta",
//   "nameMeaning": "n/a",
//   "title": "Lij",
//   "sex": true,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "Leta",
//   "mother": "Irba Ordofa",
//   "spouses": []
//   }
//   ],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "Ordofa Tufa",
//   "mother": "Meti Ayano",
//   "spouses": []
//   },
//   {
//   "id": "1.02.02",
//   "nickname": "n/a",
//   "firstName": "Berhanu",
//   "lastName": "Ordofa",
//   "nameMeaning": "n/a",
//   "title": "Mr.",
//   "sex": true,
//   "children": [
//   {
//   "id": "1.02.02.01",
//   "nickname": "n/a",
//   "firstName": "Shashitu",
//   "lastName": "Berhanu",
//   "nameMeaning": "n/a",
//   "title": "Mrs.",
//   "sex": false,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "Berhanu Ordofa",
//   "mother": "n/a",
//   "spouses": []
//   },
//   {
//   "id": "1.02.02.02",
//   "nickname": "n/a",
//   "firstName": "Dejene",
//   "lastName": "Berhanu",
//   "nameMeaning": "n/a",
//   "title": "Mr.",
//   "sex": true,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "Berhanu Ordofa",
//   "mother": "n/a",
//   "spouses": []
//   },
//   {
//   "id": "1.02.02.03",
//   "nickname": "n/a",
//   "firstName": "Bikiltu",
//   "lastName": "Berhanu",
//   "nameMeaning": "n/a",
//   "title": "Mrs.",
//   "sex": false,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "Berhanu Ordofa",
//   "mother": "n/a",
//   "spouses": []
//   },
//   {
//   "id": "1.02.02.04",
//   "nickname": "n/a",
//   "firstName": "Ayelu",
//   "lastName": "Berhanu",
//   "nameMeaning": "n/a",
//   "title": "Mrs.",
//   "sex": false,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "Berhanu Ordofa",
//   "mother": "n/a",
//   "spouses": []
//   },
//   {
//   "id": "1.02.02.05",
//   "nickname": "n/a",
//   "firstName": "Kidist",
//   "lastName": "Berhanu",
//   "nameMeaning": "n/a",
//   "title": "Mrs.",
//   "sex": false,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "Berhanu Ordofa",
//   "mother": "n/a",
//   "spouses": []
//   },
//   {
//   "id": "1.02.02.06",
//   "nickname": "n/a",
//   "firstName": "Tesfa",
//   "lastName": "Berhanu",
//   "nameMeaning": "n/a",
//   "title": "Mr.",
//   "sex": true,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "Berhanu Ordofa",
//   "mother": "n/a",
//   "spouses": []
//   }
//   ],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "Meti Ayano",
//   "mother": "Ordofa Tufa",
//   "spouses": []
//   },
//   {
//   "id": "1.02.03",
//   "nickname": "n/a",
//   "firstName": "Dibabe",
//   "lastName": "Leta",
//   "nameMeaning": "n/a",
//   "title": "Mrs.",
//   "sex": false,
//   "children": [
//   {
//   "id": "1.02.03.01",
//   "nickname": "n/a",
//   "firstName": "Rahel",
//   "lastName": "Fasil",
//   "nameMeaning": "n/a",
//   "title": "Mrs.",
//   "sex": false,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "n/a",
//   "mother": "Dibabe Leta",
//   "spouses": []
//   },
//   {
//   "id": "1.02.03.02",
//   "nickname": "n/a",
//   "firstName": "Fanta",
//   "lastName": "Oda",
//   "nameMeaning": "n/a",
//   "title": "n/a",
//   "sex": true,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "n/a",
//   "mother": "Dibabe Leta",
//   "spouses": []
//   },
//   {
//   "id": "1.02.03.03",
//   "nickname": "n/a",
//   "firstName": "Betselot",
//   "lastName": "Oda",
//   "nameMeaning": "n/a",
//   "title": "n/a",
//   "sex": false,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "n/a",
//   "mother": "Dibabe Leta",
//   "spouses": []
//   }
//   ],
//   "birthDate": "1956",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "Ordofa Tufa",
//   "mother": "Meti Ayano",
//   "spouses": []
//   }
//   ],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "19-07-2002",
//   "cementery": "21-07-2002",
//   "history": "n/a",
//   "father": "Ayano Benti",
//   "mother": "n/a",
//   "spouses": [
//   {
//   "id": "1.02.a",
//   "nickname": "n/a",
//   "firstName": "Ordofa",
//   "lastName": "Tufa",
//   "nameMeaning": "n/a",
//   "title": "Mr.",
//   "sex": true,
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "n/a",
//   "mother": "n/a"
//   }
//   ]
//   },
//   {
//   "id": "1.03",
//   "nickname": "Ade Shewa",
//   "firstName": "Kuli",
//   "lastName": "Ayano",
//   "nameMeaning": "Beautify",
//   "title": "Mrs.",
//   "sex": false,
//   "children": [
//   {
//   "id": "1.03.01",
//   "nickname": "n/a",
//   "firstName": "Shewa",
//   "lastName": "Guta",
//   "nameMeaning": "n/a",
//   "title": "Mrs.",
//   "sex": false,
//   "children": [],
//   "birthDate": "35-1933",
//   "deathDate": "7-08-2009",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "Guta Aredu",
//   "mother": "Kuli Ayano",
//   "spouses": []
//   },
//   {
//   "id": "1.03.02",
//   "nickname": "n/a",
//   "firstName": "Raji",
//   "lastName": "Guta",
//   "nameMeaning": "n/a",
//   "title": "Mr.",
//   "sex": true,
//   "children": [],
//   "birthDate": "1937",
//   "deathDate": "1962",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "Guta Aredu",
//   "mother": "Kuli Ayano",
//   "spouses": []
//   },
//   {
//   "id": "1.03.03",
//   "nickname": "n/a",
//   "firstName": "Tamiru",
//   "lastName": "Guta",
//   "nameMeaning": "n/a",
//   "title": "Mr.",
//   "sex": true,
//   "children": [
//   {
//   "id": "1.03.03.01",
//   "nickname": "n/a",
//   "firstName": "Woinshet",
//   "lastName": "Tamiru",
//   "nameMeaning": "n/a",
//   "title": "Mrs.",
//   "sex": false,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "Tamiru Guta",
//   "mother": "n/a",
//   "spouses": []
//   },
//   {
//   "id": "1.03.03.02",
//   "nickname": "n/a",
//   "firstName": "Konjit",
//   "lastName": "Tamiru",
//   "nameMeaning": "n/a",
//   "title": "Mrs.",
//   "sex": false,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "Tamiru Guta",
//   "mother": "n/a",
//   "spouses": []
//   },
//   {
//   "id": "1.03.03.03",
//   "nickname": "n/a",
//   "firstName": "Azeb",
//   "lastName": "Tamiru",
//   "nameMeaning": "n/a",
//   "title": "Mrs.",
//   "sex": false,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "Tamiru Guta",
//   "mother": "n/a",
//   "spouses": []
//   },
//   {
//   "id": "1.03.03.04",
//   "nickname": "n/a",
//   "firstName": "Selam",
//   "lastName": "Tamiru",
//   "nameMeaning": "n/a",
//   "title": "Mrs.",
//   "sex": false,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "Tamiru Guta",
//   "mother": "n/a",
//   "spouses": []
//   },
//   {
//   "id": "1.03.03.05",
//   "nickname": "n/a",
//   "firstName": "Mestawet",
//   "lastName": "Tamiru",
//   "nameMeaning": "n/a",
//   "title": "Mrs.",
//   "sex": false,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "Tamiru Guta",
//   "mother": "n/a",
//   "spouses": []
//   },
//   {
//   "id": "1.03.03.06",
//   "nickname": "n/a",
//   "firstName": "Kidist",
//   "lastName": "Tamiru",
//   "nameMeaning": "n/a",
//   "title": "Mrs.",
//   "sex": false,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "Tamiru Guta",
//   "mother": "n/a",
//   "spouses": []
//   },
//   {
//   "id": "1.03.03.07",
//   "nickname": "n/a",
//   "firstName": "Natnael",
//   "lastName": "Tamiru",
//   "nameMeaning": "n/a",
//   "title": "Mr.",
//   "sex": true,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "Tamiru Guta",
//   "mother": "n/a",
//   "spouses": []
//   }
//   ],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "Guta Aredu",
//   "mother": "Kuli Ayano",
//   "spouses": []
//   },
//   {
//   "id": "1.03.04",
//   "nickname": "n/a",
//   "firstName": "Beshadu",
//   "lastName": "Guta",
//   "nameMeaning": "n/a",
//   "title": "Ms.",
//   "sex": false,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "Guta Aredu",
//   "mother": "Kuli Ayano",
//   "spouses": []
//   },
//   {
//   "id": "1.03.05",
//   "nickname": "n/a",
//   "firstName": "Ayelu",
//   "lastName": "Guta",
//   "nameMeaning": "n/a",
//   "title": "Ms.",
//   "sex": false,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "Guta Aredu",
//   "mother": "Kuli Ayano",
//   "spouses": []
//   },
//   {
//   "id": "1.03.06",
//   "nickname": "Boki",
//   "firstName": "Mekonnen",
//   "lastName": "Guta",
//   "nameMeaning": "n/a",
//   "title": "Mr.",
//   "sex": true,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "Guta Aredu",
//   "mother": "Kuli Ayano",
//   "spouses": []
//   },
//   {
//   "id": "1.03.07",
//   "nickname": "n/a",
//   "firstName": "Bose",
//   "lastName": "Guta",
//   "nameMeaning": "n/a",
//   "title": "Ms.",
//   "sex": false,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "Guta Aredu",
//   "mother": "Kuli Ayano",
//   "spouses": []
//   },
//   {
//   "id": "1.03.08",
//   "nickname": "n/a",
//   "firstName": "Gelane",
//   "lastName": "Guta",
//   "nameMeaning": "n/a",
//   "title": "Ms.",
//   "sex": false,
//   "children": [
//   {
//   "id": "1.03.08.01",
//   "nickname": "n/a",
//   "firstName": "Zewdu",
//   "lastName": "Abebe",
//   "nameMeaning": "n/a",
//   "title": "Mr.",
//   "sex": true,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "n/a",
//   "mother": "Gelane Guta",
//   "spouses": []
//   },
//   {
//   "id": "1.03.08.02",
//   "nickname": "n/a",
//   "firstName": "Girma",
//   "lastName": "Abebe",
//   "nameMeaning": "n/a",
//   "title": "Mr.",
//   "sex": true,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "n/a",
//   "mother": "Gelane Guta",
//   "spouses": []
//   },
//   {
//   "id": "1.03.08.03",
//   "nickname": "n/a",
//   "firstName": "Getu",
//   "lastName": "Abebe",
//   "nameMeaning": "n/a",
//   "title": "Mr.",
//   "sex": true,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "n/a",
//   "mother": "Gelane Guta",
//   "spouses": []
//   },
//   {
//   "id": "1.03.08.04",
//   "nickname": "n/a",
//   "firstName": "Genet",
//   "lastName": "Abebe",
//   "nameMeaning": "n/a",
//   "title": "Mrs.",
//   "sex": false,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "n/a",
//   "mother": "Gelane Guta",
//   "spouses": []
//   },
//   {
//   "id": "1.03.08.05",
//   "nickname": "n/a",
//   "firstName": "Kuma",
//   "lastName": "Abebe",
//   "nameMeaning": "n/a",
//   "title": "Mr.",
//   "sex": true,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "n/a",
//   "mother": "Gelane Guta",
//   "spouses": []
//   },
//   {
//   "id": "1.03.08.06",
//   "nickname": "n/a",
//   "firstName": "Negash",
//   "lastName": "Abebe",
//   "nameMeaning": "n/a",
//   "title": "Mr.",
//   "sex": true,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "n/a",
//   "mother": "Gelane Guta",
//   "spouses": []
//   },
//   {
//   "id": "1.03.08.07",
//   "nickname": "n/a",
//   "firstName": "Yeshi",
//   "lastName": "Abebe",
//   "nameMeaning": "n/a",
//   "title": "Mrs.",
//   "sex": false,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "n/a",
//   "mother": "Gelane Guta",
//   "spouses": []
//   },
//   {
//   "id": "1.03.08.08",
//   "nickname": "n/a",
//   "firstName": "Bayise",
//   "lastName": "Abebe",
//   "nameMeaning": "n/a",
//   "title": "Mrs.",
//   "sex": false,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "n/a",
//   "mother": "Gelane Guta",
//   "spouses": []
//   }
//   ],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "Guta Aredu",
//   "mother": "Kuli Ayano",
//   "spouses": []
//   },
//   {
//   "id": "1.03.09",
//   "nickname": "n/a",
//   "firstName": "Aregash",
//   "lastName": "Guta",
//   "nameMeaning": "n/a",
//   "title": "Mr.",
//   "sex": true,
//   "children": [
//   {
//   "id": "1.03.09.01",
//   "nickname": "n/a",
//   "firstName": "Tinsae",
//   "lastName": "Hailu",
//   "nameMeaning": "n/a",
//   "title": "n/a",
//   "sex": "n/a",
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "n/a",
//   "mother": "Aregash Guta",
//   "spouses": []
//   },
//   {
//   "id": "1.03.09.02",
//   "nickname": "n/a",
//   "firstName": "Abenezer",
//   "lastName": "Hailu",
//   "nameMeaning": "n/a",
//   "title": "n/a",
//   "sex": "n/a",
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "n/a",
//   "mother": "Aregash Guta",
//   "spouses": []
//   }
//   ],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "Guta Aredu",
//   "mother": "n/a",
//   "spouses": []
//   }
//   ],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "Ayano Benti",
//   "mother": "n/a",
//   "spouses": [
//   {
//   "id": "1.03.a",
//   "nickname": "n/a",
//   "firstName": "Guta",
//   "lastName": "Aredu",
//   "nameMeaning": "n/a",
//   "title": "Mr.",
//   "sex": true,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "n/a",
//   "mother": "n/a", 
//   }
//   ]
//   },
//   {
//   "id": "1.04",
//   "nickname": "n/a",
//   "firstName": "Sherkite",
//   "lastName": "Ayano",
//   "nameMeaning": "n/a",
//   "title": "n/a",
//   "sex": "n/a",
//   "children": [
//   {
//   "id": "1.04.01",
//   "nickname": "n/a",
//   "firstName": "Worke",
//   "lastName": "Bikila",
//   "nameMeaning": "n/a",
//   "title": "Mrs.",
//   "sex": false,
//   "children": [
//   {
//   "id": "1.04.01.01",
//   "nickname": "n/a",
//   "firstName": "Berhanu",
//   "lastName": "Beyene",
//   "nameMeaning": "n/a",
//   "title": "n/a",
//   "sex": true,
//   "children": [
//   {
//   "id": "1.04.01.01.01",
//   "nickname": "n/a",
//   "firstName": "Azeb",
//   "lastName": "Berhanu",
//   "nameMeaning": "n/a",
//   "title": "n/a",
//   "sex": false,
//   "children": [],
//   "birthDate": "1986",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "Berhanu Beyene",
//   "mother": "Almaz Demisse",
//   "spouses": []
//   },
//   {
//   "id": "1.04.01.01.02",
//   "nickname": "n/a",
//   "firstName": "Rahel",
//   "lastName": "Berhanu",
//   "nameMeaning": "n/a",
//   "title": "n/a",
//   "sex": false,
//   "children": [],
//   "birthDate": "1986",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "Berhanu Beyene",
//   "mother": "Almaz Demisse",
//   "spouses": []
//   },
//   {
//   "id": "1.04.01.01.03",
//   "nickname": "Abi",
//   "firstName": "Abraham",
//   "lastName": "Berhanu",
//   "nameMeaning": "n/a",
//   "title": "n/a",
//   "sex": false,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "Berhanu Beyenew",
//   "mother": "Almaz Demisse",
//   "spouses": []
//   }
//   ],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "n/a",
//   "mother": "Worke Bikila",
//   "spouses": []
//   },
//   {
//   "id": "1.04.01.02",
//   "nickname": "n/a",
//   "firstName": "Joro",
//   "lastName": "Beyene",
//   "nameMeaning": "n/a",
//   "title": "Mrs.",
//   "sex": false,
//   "children": [
//   {
//   "id": "1.04.01.02.01",
//   "nickname": "Enaye",
//   "firstName": "Enatalem",
//   "lastName": "Sisay",
//   "nameMeaning": "n/a",
//   "title": "n/a",
//   "sex": false,
//   "children": [],
//   "birthDate": "18-07-1977",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "Sisay Abdisa",
//   "mother": "Joro Beyene",
//   "spouses": []
//   },
//   {
//   "id": "1.04.01.02.02",
//   "nickname": "n/a",
//   "firstName": "Melat",
//   "lastName": "Sisay",
//   "nameMeaning": "n/a",
//   "title": "n/a",
//   "sex": false,
//   "children": [
//   {
//   "id": "1.04.01.02.02.01",
//   "nickname": "n/a",
//   "firstName": "Atanyas",
//   "lastName": "Dawit",
//   "nameMeaning": "n/a",
//   "title": "n/a",
//   "sex": false,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "n/a",
//   "mother": "Melat Sisay",
//   "spouses": []
//   },
//   {
//   "id": "1.04.01.02.02.02",
//   "nickname": "n/a",
//   "firstName": "Malden",
//   "lastName": "Dawit",
//   "nameMeaning": "n/a",
//   "title": "n/a",
//   "sex": false,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "n/a",
//   "mother": "Melat Sisay",
//   "spouses": []
//   }
//   ],
//   "birthDate": "23-07-1983",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "Sisay Abdisa",
//   "mother": "Joro Beyene",
//   "spouses": []
//   }
//   ],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "n/a",
//   "mother": "Worke Bikila",
//   "spouses": []
//   },
//   {
//   "id": "1.04.01.03",
//   "nickname": "n/a",
//   "firstName": "Azalech",
//   "lastName": "Mekonnen",
//   "nameMeaning": "n/a",
//   "title": "Mrs.",
//   "sex": false,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "n/a",
//   "mother": "Worke Bikila",
//   "spouses": []
//   }
//   ],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "Bikila Bati",
//   "mother": "Sherkite Ayano",
//   "spouses": []
//   },
//   {
//   "id": "1.04.02",
//   "nickname": "n/a",
//   "firstName": "Tesfaye",
//   "lastName": "Bikila",
//   "nameMeaning": "n/a",
//   "title": "Mr.",
//   "sex": true,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "Bikila Bati",
//   "mother": "Sherkite Ayano",
//   "spouses": []
//   },
//   {
//   "id": "1.04.03",
//   "nickname": "n/a",
//   "firstName": "Asefa",
//   "lastName": "Bikila",
//   "nameMeaning": "n/a",
//   "title": "Mr.",
//   "sex": true,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "Bikila Bati",
//   "mother": "Sherkite Ayano",
//   "spouses": []
//   }
//   ],
//   "birthDate": "n/a",
//   "deathDate": "30-12-1983",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "Ayano",
//   "mother": "n/a",
//   "spouses": [
//   {
//   "id": "1.04.a",
//   "nickname": "n/a",
//   "firstName": "Bikila",
//   "lastName": "Bati",
//   "nameMeaning": "n/a",
//   "title": "Mrs.",
//   "sex": "false",
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "n/a",
//   "mother": "n/a", 
//   }
//   ]
//   },
//   {
//   "id": "1.05",
//   "nickname": "n/a",
//   "firstName": "Yadete",
//   "lastName": "Ayano",
//   "nameMeaning": "n/a",
//   "title": "Mr.",
//   "sex": true,
//   "children": [
//   {
//   "id": "1.05.01",
//   "nickname": "n/a",
//   "firstName": "Tefera",
//   "lastName": "Yadete",
//   "nameMeaning": "n/a",
//   "title": "Mr.",
//   "sex": true,
//   "children": [
//   {
//   "id": "1.05.01.01",
//   "nickname": "Sore",
//   "firstName": "Aster",
//   "lastName": "Tefera",
//   "nameMeaning": "n/a",
//   "title": "Mrs.",
//   "sex": false,
//   "children": [
//   {
//   "id": "1.05.01.01.01",
//   "nickname": "n/a",
//   "firstName": "Biruktawit",
//   "lastName": "Zewdu",
//   "nameMeaning": "n/a",
//   "title": "n/a",
//   "sex": false,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "n/a",
//   "mother": "Aster Tefera",
//   "spouses": []
//   },
//   {
//   "id": "1.05.01.01.02",
//   "nickname": "n/a",
//   "firstName": "Abenezer",
//   "lastName": "Zewdu",
//   "nameMeaning": "n/a",
//   "title": "n/a",
//   "sex": false,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "n/a",
//   "mother": "Aster Tefera",
//   "spouses": []
//   }
//   ],
//   "birthDate": "8-07-1954",
//   "deathDate": "5-11-2002",
//   "deathCause": "Disease",
//   "cementery": "Asko Gebriel",
//   "history": "n/a",
//   "father": "Tefera Yadete",
//   "mother": "n/a",
//   "spouses": []
//   },
//   {
//   "id": "1.05.01.02",
//   "nickname": "n/a",
//   "firstName": "Tigist",
//   "lastName": "Tefera",
//   "nameMeaning": "n/a",
//   "title": "Mrs.",
//   "sex": false,
//   "children": [
//   {
//   "id": "1.05.01.02.01",
//   "nickname": "n/a",
//   "firstName": "Biniyam",
//   "lastName": "Feleke",
//   "nameMeaning": "n/a",
//   "title": "n/a",
//   "sex": true,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "n/a",
//   "mother": "Tigist Tefera",
//   "spouses": []
//   },
//   {
//   "id": "1.05.01.02.02",
//   "nickname": "n/a",
//   "firstName": "Sosina",
//   "lastName": "Feleke",
//   "nameMeaning": "n/a",
//   "title": "n/a",
//   "sex": false,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "n/a",
//   "mother": "Tigist Tefera",
//   "spouses": []
//   },
//   {
//   "id": "1.05.01.02.03",
//   "nickname": "n/a",
//   "firstName": "Eskedar",
//   "lastName": "Feleke",
//   "nameMeaning": "n/a",
//   "title": "n/a",
//   "sex": true,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "n/a",
//   "mother": "Tigist Tefera",
//   "spouses": []
//   },
//   {
//   "id": "1.05.01.02.04",
//   "nickname": "n/a",
//   "firstName": "Kale Amlak",
//   "lastName": "Feleke",
//   "nameMeaning": "n/a",
//   "title": "n/a",
//   "sex": true,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "n/a",
//   "mother": "Tigist Tefera",
//   "spouses": []
//   }
//   ],
//   "birthDate": "23-05-1956",
//   "deathDate": "4-13-1992",
//   "deathCause": "Giving birth",
//   "cementery": "Asko Gebriel",
//   "history": "n/a",
//   "father": "Tefera Yadete",
//   "mother": "n/a",
//   "spouses": []
//   },
//   {
//   "id": "1.05.01.03",
//   "nickname": "Chuchu",
//   "firstName": "Shimeles",
//   "lastName": "Tefera",
//   "nameMeaning": "n/a",
//   "title": "n/a",
//   "sex": true,
//   "children": [
//   {
//   "id": "1.05.01.03.01",
//   "nickname": "n/a",
//   "firstName": "Yonatan",
//   "lastName": "Shimeles",
//   "nameMeaning": "n/a",
//   "title": "n/a",
//   "sex": true,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "n/a",
//   "mother": "Shimeles Tefera",
//   "spouses": []
//   }
//   ],
//   "birthDate": "21-09-1958",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "n/a",
//   "mother": "Tefera Yadete",
//   "spouses": []
//   },
//   {
//   "id": "1.05.01.04",
//   "nickname": "wurye",
//   "firstName": "Helen",
//   "lastName": "Tefera",
//   "nameMeaning": "n/a",
//   "title": "n/a",
//   "sex": false,
//   "children": [],
//   "birthDate": "21-07-1961",
//   "deathDate": "1980",
//   "deathCause": "disease",
//   "cementery": "Asko Gebriel",
//   "history": "n/a",
//   "father": "n/a",
//   "mother": "Tefera Yadete",
//   "spouses": []
//   },
//   {
//   "id": "1.05.01.05",
//   "nickname": "Mamush",
//   "firstName": "Ashenafi",
//   "lastName": "Tefera",
//   "nameMeaning": "n/a",
//   "title": "n/a",
//   "sex": true,
//   "children": [],
//   "birthDate": "22-10-1963",
//   "deathDate": "1980",
//   "deathCause": "disease",
//   "cementery": "Asko Gebriel",
//   "history": "n/a",
//   "father": "n/a",
//   "mother": "Tefera Yadete",
//   "spouses": []
//   },
//   {
//   "id": "1.05.01.06",
//   "nickname": "n/a",
//   "firstName": "n/a",
//   "lastName": "n/a",
//   "nameMeaning": "n/a",
//   "title": "n/a",
//   "sex": false,
//   "children": [],
//   "birthDate": "1966",
//   "deathDate": "1966",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "n/a",
//   "mother": "Tefera Yadete",
//   "spouses": []
//   },
//   {
//   "id": "1.05.01.07",
//   "nickname": "Mami",
//   "firstName": "Sisay",
//   "lastName": "Tefera",
//   "nameMeaning": "n/a",
//   "title": "n/a",
//   "sex": true,
//   "children": [
//   {
//   "id": "1.05.01.07.01",
//   "nickname": "n/a",
//   "firstName": "Adonyas",
//   "lastName": "Sisay",
//   "nameMeaning": "n/a",
//   "title": "n/a",
//   "sex": false,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "n/a",
//   "mother": "Sisay Tefera",
//   "spouses": []
//   },
//   {
//   "id": "1.05.01.07.02",
//   "nickname": "n/a",
//   "firstName": "Nuhamin",
//   "lastName": "Sisay",
//   "nameMeaning": "n/a",
//   "title": "n/a",
//   "sex": false,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "n/a",
//   "mother": "Sisay Tefera",
//   "spouses": []
//   },
//   {
//   "id": "1.05.01.07.03",
//   "nickname": "n/a",
//   "firstName": "Azarias",
//   "lastName": "Sisay",
//   "nameMeaning": "n/a",
//   "title": "n/a",
//   "sex": true,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "n/a",
//   "mother": "Sisay Tefera",
//   "spouses": []
//   }
//   ],
//   "birthDate": "28-05-1967",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "n/a",
//   "mother": "Tefera Yadete",
//   "spouses": []
//   },
//   {
//   "id": "1.05.01.08",
//   "nickname": "Hetsanu",
//   "firstName": "Getnet",
//   "lastName": "Tefera",
//   "nameMeaning": "n/a",
//   "title": "n/a",
//   "sex": true,
//   "children": [
//   {
//   "id": "1.05.01.08.01",
//   "nickname": "n/a",
//   "firstName": "Yemariam",
//   "lastName": "Getnet",
//   "nameMeaning": "n/a",
//   "title": "n/a",
//   "sex": false,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "n/a",
//   "mother": "Getnet Tefera",
//   "spouses": []
//   },
//   {
//   "id": "1.05.01.08.02",
//   "nickname": "n/a",
//   "firstName": "Loza",
//   "lastName": "Getnet",
//   "nameMeaning": "n/a",
//   "title": "n/a",
//   "sex": false,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "n/a",
//   "mother": "Getnet Tefera",
//   "spouses": []
//   },
//   {
//   "id": "1.05.01.08.03",
//   "nickname": "n/a",
//   "firstName": "Yishak",
//   "lastName": "Getnet",
//   "nameMeaning": "n/a",
//   "title": "n/a",
//   "sex": true,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "n/a",
//   "mother": "Getnet Tefera",
//   "spouses": []
//   }
//   ],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "n/a",
//   "mother": "Tefera Yadete",
//   "spouses": []
//   },
//   {
//   "id": "1.05.01.09",
//   "nickname": "Mititi",
//   "firstName": "Menbere",
//   "lastName": "Tefera",
//   "nameMeaning": "n/a",
//   "title": "n/a",
//   "sex": false,
//   "children": [
//   {
//   "id": "1.05.01.09.01",
//   "nickname": "n/a",
//   "firstName": "Manuhe",
//   "lastName": "Berhanu",
//   "nameMeaning": "n/a",
//   "title": "n/a",
//   "sex": true,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "n/a",
//   "mother": "Menbere Tefera",
//   "spouses": []
//   },
//   {
//   "id": "1.05.01.09.02",
//   "nickname": "n/a",
//   "firstName": "Elianna",
//   "lastName": "Berhanu",
//   "nameMeaning": "n/a",
//   "title": "n/a",
//   "sex": false,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "n/a",
//   "mother": "Menbere Tefera",
//   "spouses": []
//   }
//   ],
//   "birthDate": "15-12-1972",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "n/a",
//   "mother": "Tefera Yadete",
//   "spouses": []
//   },
//   {
//   "id": "1.05.01.10",
//   "nickname": "Emu",
//   "firstName": "Firehiwot",
//   "lastName": "Tefera",
//   "nameMeaning": "n/a",
//   "title": "n/a",
//   "sex": true,
//   "children": [
//   {
//   "id": "1.05.01.10.01",
//   "nickname": "n/a",
//   "firstName": "Keab",
//   "lastName": "Addisu",
//   "nameMeaning": "n/a",
//   "title": "n/a",
//   "sex": true,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "n/a",
//   "mother": "Frehiwot Yadete",
//   "spouses": []
//   },
//   {
//   "id": "1.05.01.10.02",
//   "nickname": "n/a",
//   "firstName": "Zemichael",
//   "lastName": "Addisu",
//   "nameMeaning": "n/a",
//   "title": "n/a",
//   "sex": true,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "n/a",
//   "mother": "Frehiwot Yadete",
//   "spouses": []
//   }
//   ],
//   "birthDate": "8-07-1974",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "n/a",
//   "mother": "Tefera Yadete",
//   "spouses": []
//   },
//   {
//   "id": "1.05.01.11",
//   "nickname": "n/a",
//   "firstName": "Eyesus",
//   "lastName": "Werk",
//   "nameMeaning": "n/a",
//   "title": "n/a",
//   "sex": true,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "n/a",
//   "mother": "Yadete Ayano",
//   "spouses": []
//   },
//   {
//   "id": "1.05.01.12",
//   "nickname": "n/a",
//   "firstName": "Kebede",
//   "lastName": "Tefera",
//   "nameMeaning": "n/a",
//   "title": "n/a",
//   "sex": true,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "n/a",
//   "mother": "Yadete Ayano",
//   "spouses": []
//   },
//   {
//   "id": "1.05.01.13",
//   "nickname": "n/a",
//   "firstName": "Tsegaye",
//   "lastName": "Tefera",
//   "nameMeaning": "n/a",
//   "title": "n/a",
//   "sex": true,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "n/a",
//   "mother": "Yadete Ayano",
//   "spouses": []
//   }
//   ],
//   "birthDate": "1934",
//   "deathDate": "06-2015",
//   "deathCause": "disease",
//   "cementery": "Asko Gebriel",
//   "history": "n/a",
//   "father": "Yadete Ayano",
//   "mother": "Gette",
//   "spouses": []
//   },
//   {
//   "id": "1.05.02",
//   "nickname": "n/a",
//   "firstName": "Alemwerk",
//   "lastName": "Yadete",
//   "nameMeaning": "Because of her calm character",
//   "title": "n/a",
//   "sex": false,
//   "children": [
//   {
//   "id": "1.05.02.01",
//   "nickname": "n/a",
//   "firstName": "Addis",
//   "lastName": "n/a",
//   "nameMeaning": "n/a",
//   "title": "n/a",
//   "sex": true,
//   "children": [],
//   "birthDate": "12-1966",
//   "deathDate": "10-04-2009",
//   "deathCause": "found dead",
//   "cementery": "Asko Gebriel",
//   "history": "n/a",
//   "father": "n/a",
//   "mother": "Alemwerk Yadete",
//   "spouses": []
//   }
//   ],
//   "birthDate": "1938",
//   "deathDate": "1986",
//   "deathCause": "Disease",
//   "cementery": "Asko Gebriel",
//   "history": "n/a",
//   "father": "Yadete Ayano",
//   "mother": "Mulu Melkam",
//   "spouses": []
//   },
//   {
//   "id": "1.05.03",
//   "nickname": "Bizuye",
//   "firstName": "Bizunesh",
//   "lastName": "Yadete",
//   "nameMeaning": "n/a",
//   "title": "n/a",
//   "sex": false,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "Yadete Ayano",
//   "mother": "Mulu Melkam",
//   "spouses": []
//   },
//   {
//   "id": "1.05.04",
//   "nickname": "n/a",
//   "firstName": "Taye",
//   "lastName": "Yadete",
//   "nameMeaning": "n/a",
//   "title": "n/a",
//   "sex": true,
//   "children": [
//   {
//   "id": "1.05.04.01",
//   "nickname": "Umugela",
//   "firstName": "Firehiwot",
//   "lastName": "Taye",
//   "nameMeaning": "n/a",
//   "title": "n/a",
//   "sex": false,
//   "children": [
//   {
//   "id": "1.05.04.01.01",
//   "nickname": "n/a",
//   "firstName": "Keamlak",
//   "lastName": "Teshome",
//   "nameMeaning": "n/a",
//   "title": "n/a",
//   "sex": false,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "n/a",
//   "mother": "Firehiwot Taye",
//   "spouses": []
//   },
//   {
//   "id": "1.05.04.01.02",
//   "nickname": "n/a",
//   "firstName": "Nati",
//   "lastName": "Teshome",
//   "nameMeaning": "n/a",
//   "title": "n/a",
//   "sex": true,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "n/a",
//   "mother": "Firehiwot Taye",
//   "spouses": []
//   },
//   {
//   "id": "1.05.04.01.03",
//   "nickname": "n/a",
//   "firstName": "Abigem",
//   "lastName": "Teshome",
//   "nameMeaning": "n/a",
//   "title": "n/a",
//   "sex": false,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "n/a",
//   "mother": "Firehiwot Taye",
//   "spouses": []
//   }
//   ],
//   "birthDate": "07-12-1975",
//   "deathDate": "10-04-2009",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "Taye Yadete",
//   "mother": "Tiruwerk Abebe",
//   "spouses": []
//   },
//   {
//   "id": "1.05.04.02",
//   "nickname": "Kukusha",
//   "firstName": "Misgana",
//   "lastName": "Taye",
//   "nameMeaning": "n/a",
//   "title": "Mr.",
//   "sex": true,
//   "children": [
//   {
//   "id": "1.05.04.02.01",
//   "nickname": "n/a",
//   "firstName": "Veronica",
//   "lastName": "Misgana",
//   "nameMeaning": "n/a",
//   "title": "n/a",
//   "sex": false,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "Misgana Taye",
//   "mother": "n/a",
//   "spouses": []
//   },
//   {
//   "id": "1.05.04.02.02",
//   "nickname": "n/a",
//   "firstName": "Abemelek",
//   "lastName": "Misgana",
//   "nameMeaning": "n/a",
//   "title": "n/a",
//   "sex": true,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "Misgana Taye",
//   "mother": "n/a",
//   "spouses": []
//   },
//   {
//   "id": "1.05.04.02.03",
//   "nickname": "n/a",
//   "firstName": "Azqel",
//   "lastName": "Misgana",
//   "nameMeaning": "n/a",
//   "title": "n/a",
//   "sex": true,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "Misgana Taye",
//   "mother": "n/a",
//   "spouses": []
//   }
//   ],
//   "birthDate": "19-05-1977",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "Taye Yadete",
//   "mother": "Tiruwerk Abebe",
//   "spouses": []
//   },
//   {
//   "id": "1.05.04.03",
//   "nickname": "Emuti",
//   "firstName": "Selamawit",
//   "lastName": "Taye",
//   "nameMeaning": "she is known for her silent behaviour",
//   "title": "Mrs.",
//   "sex": false,
//   "children": [
//   {
//   "id": "1.05.04.03.01",
//   "nickname": "n/a",
//   "firstName": "Eldana",
//   "lastName": "Abraham",
//   "nameMeaning": "n/a",
//   "title": "n/a",
//   "sex": false,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "n/a",
//   "mother": "Selamwait Taye",
//   "spouses": []
//   },
//   {
//   "id": "1.05.04.03.02",
//   "nickname": "n/a",
//   "firstName": "Yishak",
//   "lastName": "Abraham",
//   "nameMeaning": "n/a",
//   "title": "n/a",
//   "sex": true,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "n/a",
//   "mother": "Selamwait Taye",
//   "spouses": []
//   }
//   ],
//   "birthDate": "07-05-1978",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "Taye Yadete",
//   "mother": "Tiruwerk Abebe",
//   "spouses": []
//   },
//   {
//   "id": "1.05.04.04",
//   "nickname": "Chebsi",
//   "firstName": "Abraham",
//   "lastName": "Taye",
//   "nameMeaning": "n/a",
//   "title": "Mr.",
//   "sex": true,
//   "children": [
//   {
//   "id": "1.05.04.04.01",
//   "nickname": "n/a",
//   "firstName": "Yeshur",
//   "lastName": "Abraham",
//   "nameMeaning": "n/a",
//   "title": "n/a",
//   "sex": true,
//   "children": [],
//   "birthDate": "27-07-1979",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "Abraham Taye",
//   "mother": "n/a",
//   "spouses": []
//   },
//   {
//   "id": "1.05.04.04.02",
//   "nickname": "n/a",
//   "firstName": "Alen",
//   "lastName": "Abraham",
//   "nameMeaning": "n/a",
//   "title": "n/a",
//   "sex": true,
//   "children": [],
//   "birthDate": "27-07-1979",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "Abraham Taye",
//   "mother": "n/a",
//   "spouses": []
//   }
//   ],
//   "birthDate": "27-07-1979",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "Taye Yadete",
//   "mother": "Tiruwerk Abebe",
//   "spouses": []
//   },
//   {
//   "id": "1.05.04.05",
//   "nickname": "Hunduma",
//   "firstName": "Henok",
//   "lastName": "Taye",
//   "nameMeaning": "n/a",
//   "title": "Mr.",
//   "sex": true,
//   "children": [],
//   "birthDate": "18-06-1986",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "Taye Yadete",
//   "mother": "Tiruwerk Abebe",
//   "spouses": []
//   },
//   {
//   "id": "1.05.04.05",
//   "nickname": "Hunduma",
//   "firstName": "Henok",
//   "lastName": "Taye",
//   "nameMeaning": "n/a",
//   "title": "Mr.",
//   "sex": true,
//   "children": [],
//   "birthDate": "18-06-1986",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "Taye Yadete",
//   "mother": "Tiruwerk Abebe",
//   "spouses": []
//   }
//   ],
//   "birthDate": "12-8-1942",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "Yadete Ayano",
//   "mother": "Mulu Melkam",
//   "spouses": [
//   {
//   "id": "1.05.04.a",
//   "nickname": "n/a",
//   "firstName": "Tiruwerk",
//   "lastName": "Abebe",
//   "nameMeaning": "n/a",
//   "title": "n/a",
//   "sex": false,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "n/a",
//   "mother": "n//a",
//   "spouses": []
//   }
//   ]
//   },
//   {
//   "id": "1.05.05",
//   "nickname": "n/a",
//   "firstName": "Tamiru",
//   "lastName": "Yadete",
//   "nameMeaning": "n/a",
//   "title": "n/a",
//   "sex": false,
//   "children": [
//   {
//   "id": "1.05.05.01",
//   "nickname": "n/a",
//   "firstName": "Semehar",
//   "lastName": "Tamiru",
//   "nameMeaning": "Born in Metsewa",
//   "title": "n/a",
//   "sex": false,
//   "children": [],
//   "birthDate": "01-1972",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "Tamiru Yadete",
//   "mother": "Hiwote Zerihun",
//   "spouses": []
//   },
//   {
//   "id": "1.05.05.02",
//   "nickname": "n/a",
//   "firstName": "Alem",
//   "lastName": "Tamiru",
//   "nameMeaning": "n/a",
//   "title": "n/a",
//   "sex": false,
//   "children": [],
//   "birthDate": "07-1974",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "Tamiru Yadete",
//   "mother": "Hiwote Zerihun",
//   "spouses": []
//   },
//   {
//   "id": "1.05.05.03",
//   "nickname": "Habtamu/Sheberu",
//   "firstName": "Mesay",
//   "lastName": "Tamiru",
//   "nameMeaning": "n/a",
//   "title": "n/a",
//   "sex": false,
//   "children": [],
//   "birthDate": "23-08-1982",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "Tamiru Yadete",
//   "mother": "Hiwote Zerihun",
//   "spouses": []
//   }
//   ],
//   "birthDate": "1944",
//   "deathDate": "1982",
//   "deathCause": "in the military",
//   "cementery": "the body hasn't return",
//   "history": "n/a",
//   "father": "Yadete Ayano",
//   "mother": "Mulu Melkam",
//   "spouses": [
//   {
//   "id": "1.05.05.a",
//   "nickname": "n/a",
//   "firstName": "Hiwot",
//   "lastName": "Zerihun",
//   "nameMeaning": "n/a",
//   "title": "Mrs.",
//   "sex": false,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "n/a",
//   "mother": "n/a",
//   "spouses": []
//   }
//   ]
//   },
//   {
//   "id": "1.05.06",
//   "nickname": "n/a",
//   "firstName": "Siyoum",
//   "lastName": "Yadete",
//   "nameMeaning": "n/a",
//   "title": "n/a",
//   "sex": false,
//   "children": [],
//   "birthDate": "1947",
//   "deathDate": "1991",
//   "deathCause": "disease",
//   "cementery": "Asko Gebriel",
//   "history": "n/a",
//   "father": "Yadete Ayano",
//   "mother": "Mulu Melkam",
//   "spouses": []
//   },
//   {
//   "id": "1.05.07",
//   "nickname": "n/a",
//   "firstName": "Aselefech",
//   "lastName": "Yadete",
//   "nameMeaning": "n/a",
//   "title": "n/a",
//   "sex": false,
//   "children": [
//   {
//   "id": "1.05.07.01",
//   "nickname": "Abiti",
//   "firstName": "Yalew",
//   "lastName": "Fantahun",
//   "nameMeaning": "n/a",
//   "title": "n/a",
//   "sex": true,
//   "children": [],
//   "birthDate": "1995",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "n/a",
//   "mother": "Aselefech Yadete",
//   "spouses": []
//   }
//   ],
//   "birthDate": "1957",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "Yadete Ayano",
//   "mother": "Mulu Melkam",
//   "spouses": []
//   }
//   ],
//   "birthDate": "1915",
//   "deathDate": "1976",
//   "deathCause": "Disease",
//   "cementery": "Asko Gebriel",
//   "history": "n/a",
//   "father": "Ayano Benti",
//   "mother": "n/a",
//   "spouses": [
//   {
//   "id": "1.05.a",
//   "nickname": "n/a",
//   "firstName": "Gette",
//   "lastName": "n/a",
//   "nameMeaning": "n/a",
//   "title": "Mrs.",
//   "sex": false,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "n/a",
//   "mother": "n/a"
//   },
//   {
//   "id": "1.05.b",
//   "nickname": "n/a",
//   "firstName": "Mulu",
//   "lastName": "Melkam",
//   "nameMeaning": "n/a",
//   "title": "Mrs.",
//   "sex": false,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "n/a",
//   "mother": "n/a"
//   }
//   ]
//   },
//   {
//   "id": "1.06",
//   "nickname": "n/a",
//   "firstName": "Debele",
//   "lastName": "Ayano",
//   "nameMeaning": "n/a",
//   "title": "Mr.",
//   "sex": true,
//   "children": [
//   {
//   "id": "1.06.01",
//   "nickname": "n/a",
//   "firstName": "Getahun",
//   "lastName": "Debele",
//   "nameMeaning": "n/a",
//   "title": "Mr.",
//   "sex": true,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "04-1976",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "Debele Ayano",
//   "mother": "Bekelech Doni",
//   "spouses": []
//   },
//   {
//   "id": "1.06.02",
//   "nickname": "n/a",
//   "firstName": "Tsege",
//   "lastName": "Debele",
//   "nameMeaning": "n/a",
//   "title": "Mrs.",
//   "sex": false,
//   "children": [
//   {
//   "id": "1.06.02.01",
//   "nickname": "Hode",
//   "firstName": "Meron",
//   "lastName": "Hailu",
//   "nameMeaning": "n/a",
//   "title": "Mrs.",
//   "sex": false,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "n/a",
//   "mother": "Tsege Debele",
//   "spouses": []
//   }
//   ],
//   "birthDate": "n/a",
//   "deathDate": "14-07-2011",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "Debele Ayano",
//   "mother": "Bekelech Doni",
//   "spouses": []
//   },
//   {
//   "id": "1.06.03",
//   "nickname": "n/a",
//   "firstName": "Genet",
//   "lastName": "Debele",
//   "nameMeaning": "n/a",
//   "title": "Mrs.",
//   "sex": false,
//   "children": [
//   {
//   "id": "1.06.03.01",
//   "nickname": "n/a",
//   "firstName": "Musse",
//   "lastName": "n/a",
//   "nameMeaning": "n/a",
//   "title": "n/a",
//   "sex": true,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "n/a",
//   "mother": "Genet Debele",
//   "spouses": []
//   },
//   {
//   "id": "1.06.03.02",
//   "nickname": "Dureti",
//   "firstName": "Bezawit",
//   "lastName": "n/a",
//   "nameMeaning": "n/a",
//   "title": "n/a",
//   "sex": false,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "n/a",
//   "mother": "Genet Debele",
//   "spouses": []
//   }
//   ],
//   "birthDate": "25-10-1958",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "Debele Ayano",
//   "mother": "Atsede Chekse",
//   "spouses": []
//   },
//   {
//   "id": "1.06.04",
//   "nickname": "n/a",
//   "firstName": "Tesfanesh",
//   "lastName": "Debele",
//   "nameMeaning": "n/a",
//   "title": "Mrs.",
//   "sex": false,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "Debele Ayano",
//   "mother": "Beyenech W/tsediq",
//   "spouses": []
//   },
//   {
//   "id": "1.06.05",
//   "nickname": "n/a",
//   "firstName": "Almaz",
//   "lastName": "Debele",
//   "nameMeaning": "n/a",
//   "title": "Mrs.",
//   "sex": false,
//   "children": [
//   {
//   "id": "1.06.05.01",
//   "nickname": "Abu",
//   "firstName": "Yidnekachew",
//   "lastName": "n/a",
//   "nameMeaning": "n/a",
//   "title": "n/a",
//   "sex": true,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "n/a",
//   "mother": "Almaz Debele",
//   "spouses": []
//   },
//   {
//   "id": "1.06.05.02",
//   "nickname": "Kati",
//   "firstName": "Mistir",
//   "lastName": "n/a",
//   "nameMeaning": "n/a",
//   "title": "n/a",
//   "sex": false,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "n/a",
//   "mother": "Almaz Debele",
//   "spouses": []
//   },
//   {
//   "id": "1.06.05.03",
//   "nickname": "n/a",
//   "firstName": "Beka",
//   "lastName": "n/a",
//   "nameMeaning": "n/a",
//   "title": "n/a",
//   "sex": false,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "n/a",
//   "mother": "Almaz Debele",
//   "spouses": []
//   },
//   {
//   "id": "1.06.05.04",
//   "nickname": "n/a",
//   "firstName": "Natoli",
//   "lastName": "n/a",
//   "nameMeaning": "n/a",
//   "title": "n/a",
//   "sex": true,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "n/a",
//   "mother": "Almaz Debele",
//   "spouses": []
//   }
//   ],
//   "birthDate": "21-03-1962",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "Debele Ayano",
//   "mother": "Elfenesh Mako",
//   "spouses": []
//   },
//   {
//   "id": "1.06.06",
//   "nickname": "n/a",
//   "firstName": "Shetu",
//   "lastName": "Debele",
//   "nameMeaning": "n/a",
//   "title": "Mrs.",
//   "sex": false,
//   "children": [
//   {
//   "id": "1.06.06.01",
//   "nickname": "n/a",
//   "firstName": "Lidya",
//   "lastName": "n/a",
//   "nameMeaning": "n/a",
//   "title": "n/a",
//   "sex": false,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "n/a",
//   "mother": "Shetu Debele",
//   "spouses": []
//   },
//   {
//   "id": "1.06.06.02",
//   "nickname": "n/a",
//   "firstName": "Hawi",
//   "lastName": "n/a",
//   "nameMeaning": "n/a",
//   "title": "n/a",
//   "sex": false,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "n/a",
//   "mother": "Shetu Debele",
//   "spouses": []
//   }
//   ],
//   "birthDate": "1964",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "Debele Ayano",
//   "mother": "Atsedech Hunde",
//   "spouses": []
//   },
//   {
//   "id": "1.06.07",
//   "nickname": "n/a",
//   "firstName": "Adanech",
//   "lastName": "Debele",
//   "nameMeaning": "n/a",
//   "title": "n/a",
//   "sex": false,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "Debele Ayano",
//   "mother": "Beyenech W/tsediq",
//   "spouses": []
//   },
//   {
//   "id": "1.06.08",
//   "nickname": "n/a",
//   "firstName": "Bekelech",
//   "lastName": "Debele",
//   "nameMeaning": "n/a",
//   "title": "n/a",
//   "sex": false,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "Debele Ayano",
//   "mother": "Beyenech W/tsediq",
//   "spouses": []
//   },
//   {
//   "id": "1.06.09",
//   "nickname": "n/a",
//   "firstName": "Senedu",
//   "lastName": "Debele",
//   "nameMeaning": "n/a",
//   "title": "n/a",
//   "sex": false,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "Debele Ayano",
//   "mother": "Beyenech W/tsediq",
//   "spouses": []
//   },
//   {
//   "id": "1.06.10",
//   "nickname": "n/a",
//   "firstName": "Bizuayew",
//   "lastName": "Debele",
//   "nameMeaning": "n/a",
//   "title": "n/a",
//   "sex": false,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "Debele Ayano",
//   "mother": "Beyenech W/tsediq",
//   "spouses": []
//   },
//   {
//   "id": "1.06.11",
//   "nickname": "n/a",
//   "firstName": "Getachew",
//   "lastName": "Debele",
//   "nameMeaning": "n/a",
//   "title": "n/a",
//   "sex": true,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "Debele Ayano",
//   "mother": "Beyenech W/tsediq",
//   "spouses": []
//   }
//   ],
//   "birthDate": "n/a",
//   "deathDate": "05-05-1970",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "Ayano Benti",
//   "mother": "n/a",
//   "spouses": [
//   {
//   "id": "1.06.a",
//   "nickname": "n/a",
//   "firstName": "Bekelech",
//   "lastName": "Doni",
//   "nameMeaning": "n/a",
//   "title": "Mrs.",
//   "sex": false,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "n/a",
//   "mother": "n/a",
//   "spouses": []
//   },
//   {
//   "id": "1.06.b",
//   "nickname": "n/a",
//   "firstName": "Atsede",
//   "lastName": "Chekese",
//   "nameMeaning": "n/a",
//   "title": "Mrs.",
//   "sex": false,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "n/a",
//   "mother": "n/a",
//   "spouses": []
//   },
//   {
//   "id": "1.06.c",
//   "nickname": "n/a",
//   "firstName": "Beyenech",
//   "lastName": "W/tsadiq",
//   "nameMeaning": "n/a",
//   "title": "Mrs.",
//   "sex": false,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "n/a",
//   "mother": "n/a",
//   "spouses": []
//   },
//   {
//   "id": "1.06.d",
//   "nickname": "n/a",
//   "firstName": "Elfenesh",
//   "lastName": "Mako",
//   "nameMeaning": "n/a",
//   "title": "Mrs.",
//   "sex": false,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "n/a",
//   "mother": "n/a",
//   "spouses": []
//   },
//   {
//   "id": "1.06.e",
//   "nickname": "n/a",
//   "firstName": "Atsedech",
//   "lastName": "Hunde",
//   "nameMeaning": "n/a",
//   "title": "Mrs.",
//   "sex": false,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "n/a",
//   "mother": "n/a",
//   "spouses": []
//   }
//   ]
//   },
//   {
//   "id": "1.07",
//   "nickname": "n/a",
//   "firstName": "Chaltu",
//   "lastName": "Ayano",
//   "nameMeaning": "n/a",
//   "title": "Mrs.",
//   "sex": false,
//   "children": [
//   {
//   "id": "1.07.01",
//   "nickname": "n/a",
//   "firstName": "Adugna",
//   "lastName": "Regasa",
//   "nameMeaning": "n/a",
//   "title": "Mr.",
//   "sex": true,
//   "children": [],
//   "birthDate": "1934",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "Regasa Balcha",
//   "mother": "Chaltu Ayano",
//   "spouses": []
//   },
//   {
//   "id": "1.07.02",
//   "nickname": "n/a",
//   "firstName": "Bizunesh",
//   "lastName": "Regasa",
//   "nameMeaning": "n/a",
//   "title": "Ms.",
//   "sex": false,
//   "children": [],
//   "birthDate": "1934",
//   "deathDate": "5-05-1970",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "Regasa Balcha",
//   "mother": "Chaltu Ayano",
//   "spouses": []
//   },
//   {
//   "id": "1.07.03",
//   "nickname": "n/a",
//   "firstName": "Mekonen",
//   "lastName": "Girma",
//   "nameMeaning": "n/a",
//   "title": "Mr.",
//   "sex": true,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "n/a",
//   "mother": "Chaltu Ayano",
//   "spouses": []
//   },
//   {
//   "id": "1.07.04",
//   "nickname": "n/a",
//   "firstName": "Boge",
//   "lastName": "Girma",
//   "nameMeaning": "n/a",
//   "title": "Mrs.",
//   "sex": false,
//   "children": [],
//   "birthDate": "22-07-1959",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "n/a",
//   "mother": "Chaltu Ayano",
//   "spouses": []
//   }
//   ],
//   "birthDate": "n/a",
//   "deathDate": "23-01-2010",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "Ayano Benti",
//   "mother": "n/a",
//   "spouses": [
//   {
//   "id": "1.07.a",
//   "nickname": "n/a",
//   "firstName": "Regasa",
//   "lastName": "Balcha",
//   "nameMeaning": "n/a",
//   "title": "Mr.",
//   "sex": true,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "n/a",
//   "mother": "n/a",
//   "spouses": []
//   }
//   ]
//   },
//   {
//   "id": "1.08",
//   "nickname": "n/a",
//   "firstName": "Bekele",
//   "lastName": "Ayano",
//   "nameMeaning": "n/a",
//   "title": "Mr.",
//   "sex": true,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a", 
//   "cementery": "n/a",
//   "deathCause": "n/a",
//   "history": "n/a",
//   "father": "Ayano Benti",
//   "mother": "n/a",
//   "spouses": []
//   },
//   {
//   "id": "1.09",
//   "nickname": "n/a",
//   "firstName": "Leke",
//   "lastName": "Ayano",
//   "nameMeaning": "n/a",
//   "title": "Mrs.",
//   "sex": false,
//   "children": [
//   {
//   "id": "1.09.01",
//   "nickname": "n/a",
//   "firstName": "Tamrat",
//   "lastName": "Asefa",
//   "nameMeaning": "n/a",
//   "title": "Mr.",
//   "sex": true,
//   "children": [
//   {
//   "id": "1.09.01.01",
//   "nickname": "Bita",
//   "firstName": "Yamlak",
//   "lastName": "Tamrat",
//   "nameMeaning": "n/a",
//   "title": "n/a",
//   "sex": false,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "Tamrat Asefa ",
//   "mother": "n/a",
//   "spouses": []
//   },
//   {
//   "id": "1.09.02",
//   "nickname": "n/a",
//   "firstName": "Aberash",
//   "lastName": "Asefa",
//   "nameMeaning": "n/a",
//   "title": "Mrs.",
//   "sex": false,
//   "children": [],
//   "birthDate": "1955",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "Asefa Werdefa",
//   "mother": "Leke Ayano",
//   "spouses": []
//   }
//   ],
//   "birthDate": "12-04-1953",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "Asefa Werdefa",
//   "mother": "Leke Ayano",
//   "spouses": []
//   },
//   {
//   "id": "1.09.02",
//   "nickname": "n/a",
//   "firstName": "Aberash",
//   "lastName": "Asefa",
//   "nameMeaning": "n/a",
//   "title": "Mrs.",
//   "sex": false,
//   "children": [],
//   "birthDate": "1955",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "Asefa Werdefa",
//   "mother": "Leke Ayano",
//   "spouses": []
//   },
//   {
//   "id": "1.09.03",
//   "nickname": "n/a",
//   "firstName": "Woineshet",
//   "lastName": "Asefa",
//   "nameMeaning": "n/a",
//   "title": "Mrs.",
//   "sex": false,
//   "children": [],
//   "birthDate": "1955",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "Asefa Werdefa",
//   "mother": "Leke Ayano",
//   "spouses": []
//   }
//   ],
//   "birthDate": "06-02-1991",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "Ayano Benti",
//   "mother": "n/a",
//   "spouses": [
//   {
//   "id": "1.09.a",
//   "nickname": "n/a",
//   "firstName": "Asefa",
//   "lastName": "Werdefa",
//   "nameMeaning": "n/a",
//   "title": "Mr.",
//   "sex": true,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "n/a",
//   "mother": "n/a",
//   "spouses": []
//   }
//   ]
//   },
//   {
//   "id": "1.10",
//   "nickname": "n/a",
//   "firstName": "Teka",
//   "lastName": "Tula",
//   "nameMeaning": "n/a",
//   "title": "Colonel",
//   "sex": true,
//   "children": [
//   {
//   "id": "1.10.01",
//   "nickname": "n/a",
//   "firstName": "Samson",
//   "lastName": "Teka",
//   "nameMeaning": "n/a",
//   "title": "Dr.",
//   "sex": true,
//   "children": [
//   {
//   "id": "1.10.01.01",
//   "nickname": "n/a",
//   "firstName": "Natnael",
//   "lastName": "Samson",
//   "nameMeaning": "n/a",
//   "title": "n/a",
//   "sex": true,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "Samson Teka",
//   "mother": "n/a",
//   "spouses": []
//   },
//   {
//   "id": "1.10.01.02",
//   "nickname": "n/a",
//   "firstName": "Abigail",
//   "lastName": "Samson",
//   "nameMeaning": "n/a",
//   "title": "n/a",
//   "sex": false,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "Samson Teka",
//   "mother": "n/a",
//   "spouses": []
//   }
//   ],
//   "birthDate": "28-06-1955",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "Teka Tula",
//   "mother": "Bere Sahle",
//   "spouses": []
//   },
//   {
//   "id": "1.10.02",
//   "nickname": "n/a",
//   "firstName": "Etsegenet",
//   "lastName": "Teka",
//   "nameMeaning": "n/a",
//   "title": "Mrs.",
//   "sex": false,
//   "children": [
//   {
//   "id": "1.10.02.01",
//   "nickname": "n/a",
//   "firstName": "Zemichael",
//   "lastName": "n/a",
//   "nameMeaning": "n/a",
//   "title": "n/a",
//   "sex": true,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "n/a",
//   "mother": "Etsegenet Teka",
//   "spouses": []
//   }
//   ],
//   "birthDate": "1960",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "Teka Tula",
//   "mother": "Bere Sahle",
//   "spouses": []
//   },
//   {
//   "id": "1.10.03",
//   "nickname": "n/a",
//   "firstName": "Ensermu",
//   "lastName": "Teka",
//   "nameMeaning": "n/a",
//   "title": "Mr.",
//   "sex": true,
//   "children": [
//   {
//   "id": "1.10.03.01",
//   "nickname": "n/a",
//   "firstName": "Meron",
//   "lastName": "Ensermu",
//   "nameMeaning": "n/a",
//   "title": "n/a",
//   "sex": false,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "Ensermu Teka",
//   "mother": "n/a",
//   "spouses": []
//   },
//   {
//   "id": "1.10.03.02",
//   "nickname": "n/a",
//   "firstName": "Lensa",
//   "lastName": "Ensermu",
//   "nameMeaning": "n/a",
//   "title": "n/a",
//   "sex": false,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "Ensermu Teka",
//   "mother": "n/a",
//   "spouses": []
//   }
//   ],
//   "birthDate": "1962",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "Teka Tula",
//   "mother": "Bere Sahle",
//   "spouses": []
//   },
//   {
//   "id": "1.10.04",
//   "nickname": "Dosho",
//   "firstName": "Yada",
//   "lastName": "Teka",
//   "nameMeaning": "n/a",
//   "title": "Mrs.",
//   "sex": false,
//   "children": [
//   {
//   "id": "1.10.04.01",
//   "nickname": "n/a",
//   "firstName": "Hawi",
//   "lastName": "Teka",
//   "nameMeaning": "n/a",
//   "title": "n/a",
//   "sex": false,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "n/a",
//   "mother": "Yada Teka",
//   "spouses": []
//   },
//   {
//   "id": "1.10.04.02",
//   "nickname": "n/a",
//   "firstName": "Haika",
//   "lastName": "Teka",
//   "nameMeaning": "n/a",
//   "title": "n/a",
//   "sex": false,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "n/a",
//   "mother": "Yada Teka",
//   "spouses": []
//   }
//   ],
//   "birthDate": "07-03-1966",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "Teka Tula",
//   "mother": "Bere Sahle",
//   "spouses": []
//   },
//   {
//   "id": "1.10.05",
//   "nickname": "Fita",
//   "firstName": "Yada",
//   "lastName": "Teka",
//   "nameMeaning": "n/a",
//   "title": "Mr.",
//   "sex": true,
//   "children": [],
//   "birthDate": "01-11-1967",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "Teka Tula",
//   "mother": "Bere Sahle",
//   "spouses": []
//   }
//   ],
//   "birthDate": "1928",
//   "deathDate": "28-01-1991",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "Tula Benti",
//   "mother": "n/a",
//   "spouses": [
//   {
//   "id": "1.10.a",
//   "nickname": "n/a",
//   "firstName": "Bere",
//   "lastName": "Sahle",
//   "nameMeaning": "n/a",
//   "title": "Mrs.",
//   "sex": false,
//   "children": [],
//   "birthDate": "n/a",
//   "deathDate": "n/a",
//   "deathCause": "n/a",
//   "cementery": "n/a",
//   "history": "n/a",
//   "father": "n/a",
//   "mother": "n/a",
//   "spouses": []
//   }
//   ]
//   }
//   ]
//   }
export const data: TreeNode ={
  "id": "1",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Ayano",
  "lastName": "Benti",
  "nameMeaning": "n/a",
  "title": "Mr.",
  "sex": true,
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "n/a",
  "mother": "n/a",
  "spouses": [
  {
  "id": "1.a",
  "avatar": "https://res.cloudinary.com/dtknehocq/image/upload/v1707027889/Moti%20yidne%20family%20tree/IMG_20240203_151437_omse0n.jpg",
  "nickname": "n/a",
  "firstName": "Alemi",
  "lastName": "Dori",
  "nameMeaning": "n/a",
  "title": "Mrs.",
  "sex": "false",
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "n/a",
  "mother": "n/a"
  }
  ],
  "children": [
  {
  "id": "1.01",
  "avatar": "https://res.cloudinary.com/dtknehocq/image/upload/v1707027880/Moti%20yidne%20family%20tree/dera_s0jsc3.jpg",
  "nickname": "n/a",
  "firstName": "Dara",
  "lastName": "Ayano",
  "nameMeaning": "n/a",
  "title": "Mrs.",
  "sex": false,
  "children": [
  {
  "id": "1.01.01",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Gette",
  "lastName": "Hunde",
  "nameMeaning": "n/a",
  "title": "Ms.",
  "sex": "false",
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "Hunde Waqeyo",
  "mother": "Dara Ayano",
  "spouses": []
  },
  {
  "id": "1.01.02",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Worku",
  "lastName": "Hunde",
  "nameMeaning": "n/a",
  "title": "Mr.",
  "sex": true,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "Hunde Waqeyo",
  "mother": "Dara Ayano",
  "spouses": []
  },
  {
  "id": "1.01.03",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Geremu",
  "lastName": "Tadese",
  "nameMeaning": "n/a",
  "title": "Mrs.",
  "sex": false,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "Tadese Adicho",
  "mother": "Dara Ayano",
  "spouses": []
  },
  {
  "id": "1.01.04",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Belayitu",
  "lastName": "Tadese",
  "nameMeaning": "n/a",
  "title": "Mrs.",
  "sex": false,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "Tadese Adicho",
  "mother": "Dara Ayano",
  "spouses": []
  },
  {
  "id": "1.01.05",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Tsehay",
  "lastName": "Tadese",
  "nameMeaning": "n/a",
  "title": "Mrs.",
  "sex": false,
  "children": [
  {
  "id": "1.01.05.01",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Helen",
  "lastName": "Hailu",
  "nameMeaning": "n/a",
  "title": "Mrs.",
  "sex": false,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "n/a",
  "mother": "Tsehay Tadese",
  "spouses": []
  },
  {
  "id": "1.01.05.02",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Meskerem",
  "lastName": "Hailu",
  "nameMeaning": "n/a",
  "title": "Mrs.",
  "sex": false,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "n/a",
  "mother": "Tsehay Tadese",
  "spouses": []
  },
  {
  "id": "1.01.05.03",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Dinqalem",
  "lastName": "Hailu",
  "nameMeaning": "n/a",
  "title": "Mrs.",
  "sex": false,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "n/a",
  "mother": "Tsehay Tadese",
  "spouses": []
  },
  {
  "id": "1.01.05.04",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Meleshiw",
  "lastName": "Hailu",
  "nameMeaning": "n/a",
  "title": "Mrs.",
  "sex": false,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "n/a",
  "mother": "Tsehay Tadese",
  "spouses": []
  },
  {
  "id": "1.01.05.05",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Anuma",
  "lastName": "Hailu",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": "n/a",
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "n/a",
  "mother": "Tsehay Tadese",
  "spouses": []
  },
  {
  "id": "1.01.05.06",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Fikir",
  "lastName": "Hailu",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": "n/a",
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "n/a",
  "mother": "Tsehay Tadese",
  "spouses": []
  }
  ],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "Tadese Adicho",
  "mother": "Dara Ayano",
  "spouses": []
  },
  {
  "id": "1.01.06",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Jemal",
  "lastName": "Tadese",
  "nameMeaning": "n/a",
  "title": "Mr.",
  "sex": true,
  "children": [
  {
  "id": "1.01.06.01",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Fikadu",
  "lastName": "Jemal",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": true,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "Jemal Tadese",
  "mother": "n/a",
  "spouses": []
  },
  {
  "id": "1.01.06.02",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Saba",
  "lastName": "Jemal",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": false,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "Jemal Tadese",
  "mother": "n/a",
  "spouses": []
  },
  {
  "id": "1.01.06.03",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Mesfin",
  "lastName": "Jemal",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": true,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "Jemal Tadese",
  "mother": "n/a",
  "spouses": []
  },
  {
  "id": "1.01.06.04",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Firew",
  "lastName": "Jemal",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": true,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "Jemal Tadese",
  "mother": "n/a",
  "spouses": []
  },
  {
  "id": "1.01.06.05",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Meaza",
  "lastName": "Jemal",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": false,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "Jemal Tadese",
  "mother": "n/a",
  "spouses": []
  },
  {
  "id": "1.01.06.06",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Tekalign",
  "lastName": "Jemal",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": true,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "Jemal Tadese",
  "mother": "n/a",
  "spouses": []
  }
  ],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "n/a",
  "mother": "n/a",
  "spouses": []
  },
  {
  "id": "1.01.07",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Tirfinesh",
  "lastName": "Tadese",
  "nameMeaning": "n/a",
  "title": "Mrs.",
  "sex": false,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "Tadese Adicho",
  "mother": "Dara Ayano",
  "spouses": []
  }
  ],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "Ayano Benti",
  "mother": "Alemi Dori",
  "spouses": [
  {
  "id": "1.01.a",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Hunde",
  "lastName": "Waqeyo",
  "nameMeaning": "n/a",
  "title": "Mr.",
  "sex": "true",
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "n/a",
  "mother": "n/a"
  },
  {
  "id": "1.01.b",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Tadese",
  "lastName": "Adicho",
  "nameMeaning": "n/a",
  "title": "Mr.",
  "sex": "true",
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "n/a",
  "mother": "n/a"
  }
  ]
  },
  {
  "id": "1.02",
  "avatar": "https://res.cloudinary.com/dtknehocq/image/upload/v1707027880/Moti%20yidne%20family%20tree/metti_gvm4bs.jpg",
  "nickname": "Werq",
  "firstName": "Meti",
  "lastName": "Ayano",
  "nameMeaning": "n/a",
  "title": "Mrs.",
  "sex": false,
  "children": [
  {
  "id": "1.02.01",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Irba",
  "lastName": "Ordofa",
  "nameMeaning": "n/a",
  "title": "Mrs.",
  "sex": false,
  "children": [
  {
  "id": "1.02.01.01",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Gelane",
  "lastName": "Leta",
  "nameMeaning": "n/a",
  "title": "Mrs.",
  "sex": false,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "n/a",
  "mother": "Irba Ordofa",
  "spouses": []
  },
  {
  "id": "1.02.01.02",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Ergo",
  "lastName": "Leta",
  "nameMeaning": "n/a",
  "title": "Mrs.",
  "sex": false,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "n/a",
  "mother": "Irba Ordofa",
  "spouses": []
  },
  {
  "id": "1.02.01.03",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Bonsa",
  "lastName": "Leta",
  "nameMeaning": "n/a",
  "title": "Lij",
  "sex": "n/a",
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "n/a",
  "mother": "Irba Ordofa",
  "spouses": []
  },
  {
  "id": "1.02.01.04",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Fikru",
  "lastName": "Leta",
  "nameMeaning": "n/a",
  "title": "Mrs.",
  "sex": false,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "n/a",
  "mother": "Irba Ordofa",
  "spouses": []
  },
  {
  "id": "1.02.01.05",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Meri",
  "lastName": "Leta",
  "nameMeaning": "n/a",
  "title": "Mrs.",
  "sex": false,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "n/a",
  "mother": "Irba Ordofa",
  "spouses": []
  },
  {
  "id": "1.02.01.06",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Tejitu",
  "lastName": "Leta",
  "nameMeaning": "n/a",
  "title": "Mrs.",
  "sex": false,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "n/a",
  "mother": "Irba Ordofa",
  "spouses": []
  },
  {
  "id": "1.02.01.07",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Kuma",
  "lastName": "Leta",
  "nameMeaning": "n/a",
  "title": "Mr.",
  "sex": true,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "n/a",
  "mother": "Irba Ordofa",
  "spouses": []
  },
  {
  "id": "1.02.01.08",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Kibitu",
  "lastName": "Leta",
  "nameMeaning": "n/a",
  "title": "Mrs.",
  "sex": false,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "n/a",
  "mother": "Irba Ordofa",
  "spouses": []
  },
  {
  "id": "1.02.01.09",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Urge",
  "lastName": "Leta",
  "nameMeaning": "n/a",
  "title": "Mrs.",
  "sex": false,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "n/a",
  "mother": "Irba Ordofa",
  "spouses": []
  },
  {
  "id": "1.02.01.10",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Asefa",
  "lastName": "Leta",
  "nameMeaning": "n/a",
  "title": "Lij",
  "sex": true,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "n/a",
  "mother": "Irba Ordofa",
  "spouses": []
  }
  ],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "Ordofa Tufa",
  "mother": "Meti Ayano",
  "spouses": []
  },
  {
  "id": "1.02.02",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Berhanu",
  "lastName": "Ordofa",
  "nameMeaning": "n/a",
  "title": "Mr.",
  "sex": true,
  "children": [
  {
  "id": "1.02.02.01",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Shashitu",
  "lastName": "Berhanu",
  "nameMeaning": "n/a",
  "title": "Mrs.",
  "sex": false,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "Berhanu Ordofa",
  "mother": "n/a",
  "spouses": []
  },
  {
  "id": "1.02.02.02",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Dejene",
  "lastName": "Berhanu",
  "nameMeaning": "n/a",
  "title": "Mr.",
  "sex": true,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "Berhanu Ordofa",
  "mother": "n/a",
  "spouses": []
  },
  {
  "id": "1.02.02.03",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Bikiltu",
  "lastName": "Berhanu",
  "nameMeaning": "n/a",
  "title": "Mrs.",
  "sex": false,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "Berhanu Ordofa",
  "mother": "n/a",
  "spouses": []
  },
  {
  "id": "1.02.02.04",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Ayelu",
  "lastName": "Berhanu",
  "nameMeaning": "n/a",
  "title": "Mrs.",
  "sex": false,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "Berhanu Ordofa",
  "mother": "n/a",
  "spouses": []
  },
  {
  "id": "1.02.02.05",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Kidist",
  "lastName": "Berhanu",
  "nameMeaning": "n/a",
  "title": "Mrs.",
  "sex": false,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "Berhanu Ordofa",
  "mother": "n/a",
  "spouses": []
  },
  {
  "id": "1.02.02.06",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Tesfa",
  "lastName": "Berhanu",
  "nameMeaning": "n/a",
  "title": "Mr.",
  "sex": true,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "Berhanu Ordofa",
  "mother": "n/a",
  "spouses": []
  }
  ],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "Meti Ayano",
  "mother": "Ordofa Tufa",
  "spouses": []
  },
  {
  "id": "1.02.03",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Dibabe",
  "lastName": "Leta",
  "nameMeaning": "n/a",
  "title": "Mrs.",
  "sex": false,
  "children": [
  {
  "id": "1.02.03.01",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Rahel",
  "lastName": "Fasil",
  "nameMeaning": "n/a",
  "title": "Mrs.",
  "sex": false,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "n/a",
  "mother": "Dibabe Leta",
  "spouses": []
  },
  {
  "id": "1.02.03.02",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Fanta",
  "lastName": "Oda",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": true,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "n/a",
  "mother": "Dibabe Leta",
  "spouses": []
  },
  {
  "id": "1.02.03.03",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Betselot",
  "lastName": "Oda",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": false,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "n/a",
  "mother": "Dibabe Leta",
  "spouses": []
  }
  ],
  "birthDate": "1956",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "Ordofa Tufa",
  "mother": "Meti Ayano",
  "spouses": []
  }
  ],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "19-07-2002",
  "cementery": "21-07-2002",
  "history": "n/a",
  "father": "Ayano Benti",
  "mother": "Alemi Dori",
  "spouses": [
  {
  "id": "1.02.a",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Ordofa",
  "lastName": "Tufa",
  "nameMeaning": "n/a",
  "title": "Mr.",
  "sex": true,
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "n/a",
  "mother": "n/a"
  }
  ]
  },
  {
  "id": "1.03",
  "avatar": "https://res.cloudinary.com/dtknehocq/image/upload/v1707027880/Moti%20yidne%20family%20tree/met_zbwoqz.jpg",
  "nickname": "Ade Shewa",
  "firstName": "Kuli",
  "lastName": "Ayano",
  "nameMeaning": "Beautify",
  "title": "Mrs.",
  "sex": false,
  "children": [
  {
  "id": "1.03.01",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Shewa",
  "lastName": "Guta",
  "nameMeaning": "n/a",
  "title": "Mrs.",
  "sex": false,
  "children": [],
  "birthDate": "35-1933",
  "deathDate": "7-08-2009",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "Guta Aredu",
  "mother": "Kuli Ayano",
  "spouses": []
  },
  {
  "id": "1.03.02",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Raji",
  "lastName": "Guta",
  "nameMeaning": "n/a",
  "title": "Mr.",
  "sex": true,
  "children": [],
  "birthDate": "1937",
  "deathDate": "1962",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "Guta Aredu",
  "mother": "Kuli Ayano",
  "spouses": []
  },
  {
  "id": "1.03.03",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Tamiru",
  "lastName": "Guta",
  "nameMeaning": "n/a",
  "title": "Mr.",
  "sex": true,
  "children": [
  {
  "id": "1.03.03.01",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Woinshet",
  "lastName": "Tamiru",
  "nameMeaning": "n/a",
  "title": "Mrs.",
  "sex": false,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "Tamiru Guta",
  "mother": "n/a",
  "spouses": []
  },
  {
  "id": "1.03.03.02",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Konjit",
  "lastName": "Tamiru",
  "nameMeaning": "n/a",
  "title": "Mrs.",
  "sex": false,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "Tamiru Guta",
  "mother": "n/a",
  "spouses": []
  },
  {
  "id": "1.03.03.03",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Azeb",
  "lastName": "Tamiru",
  "nameMeaning": "n/a",
  "title": "Mrs.",
  "sex": false,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "Tamiru Guta",
  "mother": "n/a",
  "spouses": []
  },
  {
  "id": "1.03.03.04",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Selam",
  "lastName": "Tamiru",
  "nameMeaning": "n/a",
  "title": "Mrs.",
  "sex": false,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "Tamiru Guta",
  "mother": "n/a",
  "spouses": []
  },
  {
  "id": "1.03.03.05",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Mestawet",
  "lastName": "Tamiru",
  "nameMeaning": "n/a",
  "title": "Mrs.",
  "sex": false,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "Tamiru Guta",
  "mother": "n/a",
  "spouses": []
  },
  {
  "id": "1.03.03.06",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Kidist",
  "lastName": "Tamiru",
  "nameMeaning": "n/a",
  "title": "Mrs.",
  "sex": false,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "Tamiru Guta",
  "mother": "n/a",
  "spouses": []
  },
  {
  "id": "1.03.03.07",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Natnael",
  "lastName": "Tamiru",
  "nameMeaning": "n/a",
  "title": "Mr.",
  "sex": true,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "Tamiru Guta",
  "mother": "n/a",
  "spouses": []
  }
  ],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "Guta Aredu",
  "mother": "Kuli Ayano",
  "spouses": []
  },
  {
  "id": "1.03.04",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Beshadu",
  "lastName": "Guta",
  "nameMeaning": "n/a",
  "title": "Ms.",
  "sex": false,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "Guta Aredu",
  "mother": "Kuli Ayano",
  "spouses": []
  },
  {
  "id": "1.03.05",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Ayelu",
  "lastName": "Guta",
  "nameMeaning": "n/a",
  "title": "Ms.",
  "sex": false,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "Guta Aredu",
  "mother": "Kuli Ayano",
  "spouses": []
  },
  {
  "id": "1.03.06",
  "avatar": "n/a",
  "nickname": "Boki",
  "firstName": "Mekonnen",
  "lastName": "Guta",
  "nameMeaning": "n/a",
  "title": "Mr.",
  "sex": true,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "Guta Aredu",
  "mother": "Kuli Ayano",
  "spouses": []
  },
  {
  "id": "1.03.07",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Bose",
  "lastName": "Guta",
  "nameMeaning": "n/a",
  "title": "Ms.",
  "sex": false,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "Guta Aredu",
  "mother": "Kuli Ayano",
  "spouses": []
  },
  {
  "id": "1.03.08",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Gelane",
  "lastName": "Guta",
  "nameMeaning": "n/a",
  "title": "Ms.",
  "sex": false,
  "children": [
  {
  "id": "1.03.08.01",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Zewdu",
  "lastName": "Abebe",
  "nameMeaning": "n/a",
  "title": "Mr.",
  "sex": true,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "n/a",
  "mother": "Gelane Guta",
  "spouses": []
  },
  {
  "id": "1.03.08.02",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Girma",
  "lastName": "Abebe",
  "nameMeaning": "n/a",
  "title": "Mr.",
  "sex": true,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "n/a",
  "mother": "Gelane Guta",
  "spouses": []
  },
  {
  "id": "1.03.08.03",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Getu",
  "lastName": "Abebe",
  "nameMeaning": "n/a",
  "title": "Mr.",
  "sex": true,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "n/a",
  "mother": "Gelane Guta",
  "spouses": []
  },
  {
  "id": "1.03.08.04",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Genet",
  "lastName": "Abebe",
  "nameMeaning": "n/a",
  "title": "Mrs.",
  "sex": false,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "n/a",
  "mother": "Gelane Guta",
  "spouses": []
  },
  {
  "id": "1.03.08.05",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Kuma",
  "lastName": "Abebe",
  "nameMeaning": "n/a",
  "title": "Mr.",
  "sex": true,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "n/a",
  "mother": "Gelane Guta",
  "spouses": []
  },
  {
  "id": "1.03.08.06",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Negash",
  "lastName": "Abebe",
  "nameMeaning": "n/a",
  "title": "Mr.",
  "sex": true,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "n/a",
  "mother": "Gelane Guta",
  "spouses": []
  },
  {
  "id": "1.03.08.07",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Yeshi",
  "lastName": "Abebe",
  "nameMeaning": "n/a",
  "title": "Mrs.",
  "sex": false,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "n/a",
  "mother": "Gelane Guta",
  "spouses": []
  },
  {
  "id": "1.03.08.08",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Bayise",
  "lastName": "Abebe",
  "nameMeaning": "n/a",
  "title": "Mrs.",
  "sex": false,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "n/a",
  "mother": "Gelane Guta",
  "spouses": []
  }
  ],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "Guta Aredu",
  "mother": "Kuli Ayano",
  "spouses": []
  },
  {
  "id": "1.03.09",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Aregash",
  "lastName": "Guta",
  "nameMeaning": "n/a",
  "title": "Mr.",
  "sex": true,
  "children": [
  {
  "id": "1.03.09.01",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Tinsae",
  "lastName": "Hailu",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": "n/a",
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "n/a",
  "mother": "Aregash Guta",
  "spouses": []
  },
  {
  "id": "1.03.09.02",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Abenezer",
  "lastName": "Hailu",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": "n/a",
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "n/a",
  "mother": "Aregash Guta",
  "spouses": []
  }
  ],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "Guta Aredu",
  "mother": "n/a",
  "spouses": []
  }
  ],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "Ayano Benti",
  "mother": "Alemi Dori",
  "spouses": [
  {
  "id": "1.03.a",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Guta",
  "lastName": "Aredu",
  "nameMeaning": "n/a",
  "title": "Mr.",
  "sex": true,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "n/a",
  "mother": "n/a",
  "spouses": []
  }
  ]
  },
  {
  "id": "1.04",
  "avatar": "https://res.cloudinary.com/dtknehocq/image/upload/v1707027880/Moti%20yidne%20family%20tree/sherqite_ztxceu.jpg",
  "nickname": "n/a",
  "firstName": "Sherkite",
  "lastName": "Ayano",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": "n/a",
  "children": [
  {
  "id": "1.04.01",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Worke",
  "lastName": "Bikila",
  "nameMeaning": "n/a",
  "title": "Mrs.",
  "sex": false,
  "children": [
  {
  "id": "1.04.01.01",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Berhanu",
  "lastName": "Beyene",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": true,
  "children": [
  {
  "id": "1.04.01.01.01",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Azeb",
  "lastName": "Berhanu",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": false,
  "children": [],
  "birthDate": "1986",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "Berhanu Beyene",
  "mother": "Almaz Demisse",
  "spouses": []
  },
  {
  "id": "1.04.01.01.02",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Rahel",
  "lastName": "Berhanu",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": false,
  "children": [],
  "birthDate": "1986",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "Berhanu Beyene",
  "mother": "Almaz Demisse",
  "spouses": []
  },
  {
  "id": "1.04.01.01.03",
  "avatar": "n/a",
  "nickname": "Abi",
  "firstName": "Abraham",
  "lastName": "Berhanu",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": false,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "Berhanu Beyenew",
  "mother": "Almaz Demisse",
  "spouses": []
  }
  ],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "n/a",
  "mother": "Worke Bikila",
  "spouses": []
  },
  {
  "id": "1.04.01.02",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Joro",
  "lastName": "Beyene",
  "nameMeaning": "n/a",
  "title": "Mrs.",
  "sex": false,
  "children": [
  {
  "id": "1.04.01.02.01",
  "avatar": "n/a",
  "nickname": "Enaye",
  "firstName": "Enatalem",
  "lastName": "Sisay",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": false,
  "children": [],
  "birthDate": "18-07-1977",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "Sisay Abdisa",
  "mother": "Joro Beyene",
  "spouses": []
  },
  {
  "id": "1.04.01.02.02",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Melat",
  "lastName": "Sisay",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": false,
  "children": [
  {
  "id": "1.04.01.02.02.01",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Atanyas",
  "lastName": "Dawit",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": false,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "n/a",
  "mother": "Melat Sisay",
  "spouses": []
  },
  {
  "id": "1.04.01.02.02.02",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Malden",
  "lastName": "Dawit",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": false,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "n/a",
  "mother": "Melat Sisay",
  "spouses": []
  }
  ],
  "birthDate": "23-07-1983",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "Sisay Abdisa",
  "mother": "Joro Beyene",
  "spouses": []
  }
  ],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "n/a",
  "mother": "Worke Bikila",
  "spouses": []
  },
  {
  "id": "1.04.01.03",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Azalech",
  "lastName": "Mekonnen",
  "nameMeaning": "n/a",
  "title": "Mrs.",
  "sex": false,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "n/a",
  "mother": "Worke Bikila",
  "spouses": []
  }
  ],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "Bikila Bati",
  "mother": "Sherkite Ayano",
  "spouses": []
  },
  {
  "id": "1.04.02",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Tesfaye",
  "lastName": "Bikila",
  "nameMeaning": "n/a",
  "title": "Mr.",
  "sex": true,
  "children": [
  {
  "id": "1.04.02.01",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Tadele",
  "lastName": "Tesfaye",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": true,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "Tesfaye Bikila",
  "mother": "n/a",
  "spouses": []
  },
  {
  "id": "1.04.02.02",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Kiya",
  "lastName": "Tesfaye",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": true,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "Tesfaye Bikila",
  "mother": "n/a",
  "spouses": []
  }
  ],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "Bikila Bati",
  "mother": "Sherkite Ayano",
  "spouses": []
  },
  {
  "id": "1.04.03",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Asefa",
  "lastName": "Bikila",
  "nameMeaning": "n/a",
  "title": "Mr.",
  "sex": true,
  "children": [
  {
  "id": "1.04.03.01",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Hawi",
  "lastName": "Asefa",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": false,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "Asefa Bikila",
  "mother": "n/a",
  "spouses": []
  },
  {
  "id": "1.04.03.02",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Sifen",
  "lastName": "Asefa",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": false,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "Asefa Bikila",
  "mother": "n/a",
  "spouses": []
  },
  {
  "id": "1.04.03.03",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Tsegaye",
  "lastName": "Asefa",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": true,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "Asefa Bikila",
  "mother": "n/a",
  "spouses": []
  }
  ],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "Bikila Bati",
  "mother": "Sherkite Ayano",
  "spouses": []
  }
  ],
  "birthDate": "n/a",
  "deathDate": "30-12-1983",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "Ayano",
  "mother": "Alemi Dori",
  "spouses": [
  {
  "id": "1.04.a",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Bikila",
  "lastName": "Bati",
  "nameMeaning": "n/a",
  "title": "Mrs.",
  "sex": "false",
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "n/a",
  "mother": "n/a",
  "spouses": []
  }
  ]
  },
  {
  "id": "1.05",
  "avatar": "https://res.cloudinary.com/dtknehocq/image/upload/v1707027883/Moti%20yidne%20family%20tree/yadete_ofsd8b.jpg",
  "nickname": "n/a",
  "firstName": "Yadete",
  "lastName": "Ayano",
  "nameMeaning": "n/a",
  "title": "Mr.",
  "sex": true,
  "children": [
  {
  "id": "1.05.01",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Tefera",
  "lastName": "Yadete",
  "nameMeaning": "n/a",
  "title": "Mr.",
  "sex": true,
  "children": [
  {
  "id": "1.05.01.01",
  "avatar": "n/a",
  "nickname": "Sore",
  "firstName": "Aster",
  "lastName": "Tefera",
  "nameMeaning": "n/a",
  "title": "Mrs.",
  "sex": false,
  "children": [
  {
  "id": "1.05.01.01.01",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Biruktawit",
  "lastName": "Zewdu",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": false,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "n/a",
  "mother": "Aster Tefera",
  "spouses": []
  },
  {
  "id": "1.05.01.01.02",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Abenezer",
  "lastName": "Zewdu",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": false,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "n/a",
  "mother": "Aster Tefera",
  "spouses": []
  }
  ],
  "birthDate": "8-07-1954",
  "deathDate": "5-11-2002",
  "deathCause": "Disease",
  "cementery": "Asko Gebriel",
  "history": "n/a",
  "father": "Tefera Yadete",
  "mother": "n/a",
  "spouses": []
  },
  {
  "id": "1.05.01.02",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Tigist",
  "lastName": "Tefera",
  "nameMeaning": "n/a",
  "title": "Mrs.",
  "sex": false,
  "children": [
  {
  "id": "1.05.01.02.01",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Biniyam",
  "lastName": "Feleke",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": true,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "n/a",
  "mother": "Tigist Tefera",
  "spouses": []
  },
  {
  "id": "1.05.01.02.02",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Sosina",
  "lastName": "Feleke",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": false,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "n/a",
  "mother": "Tigist Tefera",
  "spouses": []
  },
  {
  "id": "1.05.01.02.03",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Eskedar",
  "lastName": "Feleke",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": true,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "n/a",
  "mother": "Tigist Tefera",
  "spouses": []
  },
  {
  "id": "1.05.01.02.04",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Kale Amlak",
  "lastName": "Feleke",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": true,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "n/a",
  "mother": "Tigist Tefera",
  "spouses": []
  }
  ],
  "birthDate": "23-05-1956",
  "deathDate": "4-13-1992",
  "deathCause": "Giving birth",
  "cementery": "Asko Gebriel",
  "history": "n/a",
  "father": "Tefera Yadete",
  "mother": "n/a",
  "spouses": []
  },
  {
  "id": "1.05.01.03",
  "avatar": "n/a",
  "nickname": "Chuchu",
  "firstName": "Shimeles",
  "lastName": "Tefera",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": true,
  "children": [
  {
  "id": "1.05.01.03.01",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Yonatan",
  "lastName": "Shimeles",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": true,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "Shimeles Tefera",
  "mother": "n/a",
  "spouses": []
  }
  ],
  "birthDate": "21-09-1958",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "Tefera Yadete",
  "mother": "n/a",
  "spouses": []
  },
  {
  "id": "1.05.01.04",
  "avatar": "n/a",
  "nickname": "wurye",
  "firstName": "Helen",
  "lastName": "Tefera",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": false,
  "children": [],
  "birthDate": "21-07-1961",
  "deathDate": "1980",
  "deathCause": "disease",
  "cementery": "Asko Gebriel",
  "history": "n/a",
  "father": "Tefera Yadete",
  "mother": "n/a",
  "spouses": []
  },
  {
  "id": "1.05.01.05",
  "avatar": "n/a",
  "nickname": "Mamush",
  "firstName": "Ashenafi",
  "lastName": "Tefera",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": true,
  "children": [],
  "birthDate": "22-10-1963",
  "deathDate": "1980",
  "deathCause": "disease",
  "cementery": "Asko Gebriel",
  "history": "n/a",
  "father": "Tefera Yadete",
  "mother": "n/a",
  "spouses": []
  },
  {
  "id": "1.05.01.06",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "n/a",
  "lastName": "n/a",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": false,
  "children": [],
  "birthDate": "1966",
  "deathDate": "1966",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "Tefera Yadete",
  "mother": "n/a",
  "spouses": []
  },
  {
  "id": "1.05.01.07",
  "avatar": "n/a",
  "nickname": "Mami",
  "firstName": "Sisay",
  "lastName": "Tefera",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": true,
  "children": [
  {
  "id": "1.05.01.07.01",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Adonyas",
  "lastName": "Sisay",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": false,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "n/a",
  "mother": "Sisay Tefera",
  "spouses": []
  },
  {
  "id": "1.05.01.07.02",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Nuhamin",
  "lastName": "Sisay",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": false,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "n/a",
  "mother": "Sisay Tefera",
  "spouses": []
  },
  {
  "id": "1.05.01.07.03",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Azarias",
  "lastName": "Sisay",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": true,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "n/a",
  "mother": "Sisay Tefera",
  "spouses": []
  }
  ],
  "birthDate": "28-05-1967",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "Tefera Yadete",
  "mother": "n/a",
  "spouses": []
  },
  {
  "id": "1.05.01.08",
  "avatar": "n/a",
  "nickname": "Hetsanu",
  "firstName": "Getnet",
  "lastName": "Tefera",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": true,
  "children": [
  {
  "id": "1.05.01.08.01",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Yemariam",
  "lastName": "Getnet",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": false,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "n/a",
  "mother": "Getnet Tefera",
  "spouses": []
  },
  {
  "id": "1.05.01.08.02",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Loza",
  "lastName": "Getnet",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": false,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "n/a",
  "mother": "Getnet Tefera",
  "spouses": []
  },
  {
  "id": "1.05.01.08.03",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Yishak",
  "lastName": "Getnet",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": true,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "n/a",
  "mother": "Getnet Tefera",
  "spouses": []
  }
  ],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "spouses": [],
  "father": "Tefera Yadete",
  "mother": "n/a"
  },
  {
  "id": "1.05.01.09",
  "avatar": "n/a",
  "nickname": "Mititi",
  "firstName": "Menbere",
  "lastName": "Tefera",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": false,
  "children": [
  {
  "id": "1.05.01.09.01",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Manuhe",
  "lastName": "Berhanu",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": true,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "n/a",
  "mother": "Menbere Tefera",
  "spouses": []
  },
  {
  "id": "1.05.01.09.02",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Elianna",
  "lastName": "Berhanu",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": false,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "n/a",
  "mother": "Menbere Tefera",
  "spouses": []
  }
  ],
  "birthDate": "15-12-1972",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "Tefera Yadete",
  "mother": "n/a",
  "spouses": []
  },
  {
  "id": "1.05.01.10",
  "avatar": "n/a",
  "nickname": "Emu",
  "firstName": "Firehiwot",
  "lastName": "Tefera",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": true,
  "children": [
  {
  "id": "1.05.01.10.01",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Keab",
  "lastName": "Addisu",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": true,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "n/a",
  "mother": "Frehiwot Tefera",
  "spouses": []
  },
  {
  "id": "1.05.01.10.02",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Zemichael",
  "lastName": "Addisu",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": true,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "n/a",
  "mother": "Frehiwot Tefera",
  "spouses": []
  }
  ],
  "birthDate": "8-07-1974",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "Tefera Yadete",
  "mother": "n/a",
  "spouses": []
  },
  {
  "id": "1.05.01.11",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Eyesus",
  "lastName": "Tefera",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": true,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "n/a",
  "mother": "Tefera Yadete",
  "spouses": []
  },
  {
  "id": "1.05.01.12",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Kebede",
  "lastName": "Tefera",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": true,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "n/a",
  "mother": "Tefera Yadete",
  "spouses": []
  },
  {
  "id": "1.05.01.13",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Tsegaye",
  "lastName": "Tefera",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": false,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "n/a",
  "mother": "Tefera Yadete",
  "spouses": []
  },
  {
  "id": "1.05.01.14",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Tsige",
  "lastName": "Tefera",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": true,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "n/a",
  "mother": "Tefera Yadete",
  "spouses": []
  }
  ],
  "birthDate": "1934",
  "deathDate": "06-2015",
  "deathCause": "disease",
  "cementery": "Asko Gebriel",
  "history": "n/a",
  "father": "Yadete Ayano",
  "mother": "Gette",
  "spouses": []
  },
  {
  "id": "1.05.02",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Amelewerk",
  "lastName": "Yadete",
  "nameMeaning": "Because of her calm character",
  "title": "n/a",
  "sex": false,
  "children": [
  {
  "id": "1.05.02.01",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Addis",
  "lastName": "n/a",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": true,
  "children": [],
  "birthDate": "12-1966",
  "deathDate": "10-04-2009",
  "deathCause": "found dead",
  "cementery": "Asko Gebriel",
  "history": "n/a",
  "father": "n/a",
  "mother": "Amelewerk Yadete",
  "spouses": []
  }
  ],
  "birthDate": "1938",
  "deathDate": "1986",
  "deathCause": "Disease",
  "cementery": "Asko Gebriel",
  "history": "n/a",
  "father": "Yadete Ayano",
  "mother": "Mulu Melkam",
  "spouses": []
  },
  {
  "id": "1.05.03",
  "avatar": "n/a",
  "nickname": "Bizuye",
  "firstName": "Bizunesh",
  "lastName": "Yadete",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": false,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "Yadete Ayano",
  "mother": "Mulu Melkam",
  "spouses": []
  },
  {
  "id": "1.05.04",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Taye",
  "lastName": "Yadete",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": true,
  "children": [
  {
  "id": "1.05.04.01",
  "avatar": "n/a",
  "nickname": "Umugela",
  "firstName": "Firehiwot",
  "lastName": "Taye",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": false,
  "children": [
  {
  "id": "1.05.04.01.01",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Keamlak",
  "lastName": "Teshome",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": false,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "n/a",
  "mother": "Firehiwot Taye",
  "spouses": []
  },
  {
  "id": "1.05.04.01.02",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Nati",
  "lastName": "Teshome",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": true,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "n/a",
  "mother": "Firehiwot Taye",
  "spouses": []
  },
  {
  "id": "1.05.04.01.03",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Abigem",
  "lastName": "Teshome",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": false,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "n/a",
  "mother": "Firehiwot Taye",
  "spouses": []
  }
  ],
  "birthDate": "07-12-1975",
  "deathDate": "10-04-2009",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "Taye Yadete",
  "mother": "Tiruwerk Abebe",
  "spouses": []
  },
  {
  "id": "1.05.04.02",
  "avatar": "n/a",
  "nickname": "Kukusha",
  "firstName": "Misgana",
  "lastName": "Taye",
  "nameMeaning": "n/a",
  "title": "Mr.",
  "sex": true,
  "children": [
  {
  "id": "1.05.04.02.01",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Veronica",
  "lastName": "Misgana",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": false,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "Misgana Taye",
  "mother": "n/a",
  "spouses": []
  },
  {
  "id": "1.05.04.02.02",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Abemelek",
  "lastName": "Misgana",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": true,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "Misgana Taye",
  "mother": "n/a",
  "spouses": []
  },
  {
  "id": "1.05.04.02.03",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Azqel",
  "lastName": "Misgana",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": true,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "Misgana Taye",
  "mother": "n/a",
  "spouses": []
  }
  ],
  "birthDate": "19-05-1977",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "Taye Yadete",
  "mother": "Tiruwerk Abebe",
  "spouses": []
  },
  {
  "id": "1.05.04.03",
  "avatar": "n/a",
  "nickname": "Emuti",
  "firstName": "Selamawit",
  "lastName": "Taye",
  "nameMeaning": "she is known for her silent behaviour",
  "title": "Mrs.",
  "sex": false,
  "children": [
  {
  "id": "1.05.04.03.01",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Eldana",
  "lastName": "Abraham",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": false,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "n/a",
  "mother": "Selamwait Taye",
  "spouses": []
  },
  {
  "id": "1.05.04.03.02",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Yishak",
  "lastName": "Abraham",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": true,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "n/a",
  "mother": "Selamwait Taye",
  "spouses": []
  }
  ],
  "birthDate": "07-05-1978",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "Taye Yadete",
  "mother": "Tiruwerk Abebe",
  "spouses": []
  },
  {
  "id": "1.05.04.04",
  "avatar": "n/a",
  "nickname": "Chebsi",
  "firstName": "Abraham",
  "lastName": "Taye",
  "nameMeaning": "n/a",
  "title": "Mr.",
  "sex": true,
  "children": [
  {
  "id": "1.05.04.04.01",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Yeshur",
  "lastName": "Abraham",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": true,
  "children": [],
  "birthDate": "27-07-1979",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "Abraham Taye",
  "mother": "n/a",
  "spouses": []
  },
  {
  "id": "1.05.04.04.02",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Alen",
  "lastName": "Abraham",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": true,
  "children": [],
  "birthDate": "27-07-1979",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "Abraham Taye",
  "mother": "n/a",
  "spouses": []
  }
  ],
  "birthDate": "27-07-1979",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "Taye Yadete",
  "mother": "Tiruwerk Abebe",
  "spouses": []
  },
  {
  "id": "1.05.04.05",
  "avatar": "n/a",
  "nickname": "Hunduma",
  "firstName": "Henok",
  "lastName": "Taye",
  "nameMeaning": "n/a",
  "title": "Mr.",
  "sex": true,
  "children": [],
  "birthDate": "18-06-1986",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "Taye Yadete",
  "mother": "Tiruwerk Abebe",
  "spouses": []
  },
  {
  "id": "1.05.04.05",
  "avatar": "n/a",
  "nickname": "Hunduma",
  "firstName": "Henok",
  "lastName": "Taye",
  "nameMeaning": "n/a",
  "title": "Mr.",
  "sex": true,
  "children": [],
  "birthDate": "18-06-1986",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "Taye Yadete",
  "mother": "Tiruwerk Abebe",
  "spouses": []
  }
  ],
  "birthDate": "12-8-1942",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "Yadete Ayano",
  "mother": "Mulu Melkam",
  "spouses": [
  {
  "id": "1.05.04.a",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Tiruwerk",
  "lastName": "Abebe",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": false,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "n/a",
  "mother": "n/a",
  "spouses": []
  }
  ]
  },
  {
  "id": "1.05.05",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Tamiru",
  "lastName": "Yadete",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": false,
  "children": [
  {
  "id": "1.05.05.01",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Semehar",
  "lastName": "Tamiru",
  "nameMeaning": "Born in Metsewa",
  "title": "n/a",
  "sex": false,
  "children": [],
  "birthDate": "01-1972",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "Tamiru Yadete",
  "mother": "Hiwote Zerihun",
  "spouses": []
  },
  {
  "id": "1.05.05.02",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Alem",
  "lastName": "Tamiru",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": false,
  "children": [],
  "birthDate": "07-1974",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "Tamiru Yadete",
  "mother": "Hiwote Zerihun",
  "spouses": []
  },
  {
  "id": "1.05.05.03",
  "avatar": "n/a",
  "nickname": "Habtamu/Sheberu",
  "firstName": "Mesay",
  "lastName": "Tamiru",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": false,
  "children": [],
  "birthDate": "23-08-1982",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "Tamiru Yadete",
  "mother": "Hiwote Zerihun",
  "spouses": []
  }
  ],
  "birthDate": "1944",
  "deathDate": "1982",
  "deathCause": "in the military",
  "cementery": "the body hasn't return",
  "history": "n/a",
  "father": "Yadete Ayano",
  "mother": "Mulu Melkam",
  "spouses": [
  {
  "id": "1.05.05.a",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Hiwot",
  "lastName": "Zerihun",
  "nameMeaning": "n/a",
  "title": "Mrs.",
  "sex": false,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "n/a",
  "mother": "n/a",
  "spouses": []
  }
  ]
  },
  {
  "id": "1.05.06",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Siyoum",
  "lastName": "Yadete",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": false,
  "children": [],
  "birthDate": "1947",
  "deathDate": "1991",
  "deathCause": "disease",
  "cementery": "Asko Gebriel",
  "history": "n/a",
  "father": "Yadete Ayano",
  "mother": "Mulu Melkam",
  "spouses": []
  },
  {
  "id": "1.05.07",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Aselefech",
  "lastName": "Yadete",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": false,
  "children": [
  {
  "id": "1.05.07.01",
  "avatar": "n/a",
  "nickname": "Abiti",
  "firstName": "Yalew",
  "lastName": "Fantahun",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": true,
  "children": [],
  "birthDate": "1995",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "n/a",
  "mother": "Aselefech Yadete",
  "spouses": []
  }
  ],
  "birthDate": "1957",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "Yadete Ayano",
  "mother": "Mulu Melkam",
  "spouses": []
  }
  ],
  "birthDate": "1915",
  "deathDate": "1976",
  "deathCause": "Disease",
  "cementery": "Asko Gebriel",
  "history": "n/a",
  "father": "Ayano Benti",
  "mother": "Alemi Dori",
  "spouses": [
  {
  "id": "1.05.a",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Gette",
  "lastName": "n/a",
  "nameMeaning": "n/a",
  "title": "Mrs.",
  "sex": false,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "n/a",
  "mother": "n/a"
  },
  {
  "id": "1.05.b",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Mulu",
  "lastName": "Melkam",
  "nameMeaning": "n/a",
  "title": "Mrs.",
  "sex": false,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "n/a",
  "mother": "n/a"
  }
  ]
  },
  {
  "id": "1.06",
  "avatar": "https://res.cloudinary.com/dtknehocq/image/upload/v1707027879/Moti%20yidne%20family%20tree/debele_qsrgfi.jpg",
  "nickname": "n/a",
  "firstName": "Debele",
  "lastName": "Ayano",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": true,
  "children": [
  {
  "id": "1.06.01",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Getahun",
  "lastName": "Debele",
  "nameMeaning": "n/a",
  "title": "Mr.",
  "sex": true,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "04-1976",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "Debele Ayano",
  "mother": "Bekelech Doni",
  "spouses": []
  },
  {
  "id": "1.06.02",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Tsege",
  "lastName": "Debele",
  "nameMeaning": "n/a",
  "title": "Mrs.",
  "sex": false,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "14-07-2011",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "Debele Ayano",
  "mother": "Bekelech Doni",
  "spouses": []
  },
  {
  "id": "1.06.03",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Genet",
  "lastName": "Debele",
  "nameMeaning": "n/a",
  "title": "Mrs.",
  "sex": false,
  "children": [
  {
  "id": "1.06.03.01",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Musse",
  "lastName": "Mulugeta",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": true,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "n/a",
  "mother": "Genet Debele",
  "spouses": []
  },
  {
  "id": "1.06.03.02",
  "avatar": "n/a",
  "nickname": "Dureti",
  "firstName": "Bezawit",
  "lastName": "Mulugeta",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": false,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "n/a",
  "mother": "Genet Debele",
  "spouses": []
  }
  ],
  "birthDate": "25-10-1958",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "Debele Ayano",
  "mother": "Atsede Chekse",
  "spouses": []
  },
  {
  "id": "1.06.04",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Tesfanesh",
  "lastName": "Debele",
  "nameMeaning": "n/a",
  "title": "Mrs.",
  "sex": false,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "Debele Ayano",
  "mother": "Beyenech W/tsediq",
  "spouses": []
  },
  {
  "id": "1.06.05",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Almaz",
  "lastName": "Debele",
  "nameMeaning": "n/a",
  "title": "Mrs.",
  "sex": false,
  "children": [
  {
  "id": "1.06.05.01",
  "avatar": "n/a",
  "nickname": "Abu",
  "firstName": "Yidnekachew",
  "lastName": "n/a",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": true,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "n/a",
  "mother": "Almaz Debele",
  "spouses": []
  },
  {
  "id": "1.06.05.02",
  "avatar": "n/a",
  "nickname": "Kati",
  "firstName": "Mistir",
  "lastName": "n/a",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": false,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "n/a",
  "mother": "Almaz Debele",
  "spouses": []
  },
  {
  "id": "1.06.05.03",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Beka",
  "lastName": "n/a",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": false,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "n/a",
  "mother": "Almaz Debele",
  "spouses": []
  },
  {
  "id": "1.06.05.04",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Natoli",
  "lastName": "n/a",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": true,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "n/a",
  "mother": "Almaz Debele",
  "spouses": []
  }
  ],
  "birthDate": "21-03-1962",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "Debele Ayano",
  "mother": "Elfenesh Mako",
  "spouses": []
  },
  {
  "id": "1.06.06",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Shetu",
  "lastName": "Debele",
  "nameMeaning": "n/a",
  "title": "Mrs.",
  "sex": false,
  "children": [
  {
  "id": "1.06.06.01",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Lidya",
  "lastName": "n/a",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": false,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "n/a",
  "mother": "Shetu Debele",
  "spouses": []
  },
  {
  "id": "1.06.06.02",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Hawi",
  "lastName": "n/a",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": false,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "n/a",
  "mother": "Shetu Debele",
  "spouses": []
  }
  ],
  "birthDate": "1964",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "Debele Ayano",
  "mother": "Atsedech Hunde",
  "spouses": []
  },
  {
  "id": "1.06.07",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Adanech",
  "lastName": "Debele",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": false,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "Debele Ayano",
  "mother": "Beyenech W/tsediq",
  "spouses": []
  },
  {
  "id": "1.06.08",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Bekelech",
  "lastName": "Debele",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": false,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "Debele Ayano",
  "mother": "Beyenech W/tsediq",
  "spouses": []
  },
  {
  "id": "1.06.09",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Senedu",
  "lastName": "Debele",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": false,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "Debele Ayano",
  "mother": "Beyenech W/tsediq",
  "spouses": []
  },
  {
  "id": "1.06.10",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Bizuayew",
  "lastName": "Debele",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": false,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "Debele Ayano",
  "mother": "Beyenech W/tsediq",
  "spouses": []
  },
  {
  "id": "1.06.11",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Getachew",
  "lastName": "Debele",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": true,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "Debele Ayano",
  "mother": "Beyenech W/tsediq",
  "spouses": []
  }
  ],
  "birthDate": "n/a",
  "deathDate": "05-05-1970",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "Ayano Benti",
  "mother": "Alemi Dori",
  "spouses": [
  {
  "id": "1.06.a",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Bekelech",
  "lastName": "Doni",
  "nameMeaning": "n/a",
  "title": "Mrs.",
  "sex": false,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "n/a",
  "mother": "n/a",
  "spouses": []
  },
  {
  "id": "1.06.b",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Atsede",
  "lastName": "Chekese",
  "nameMeaning": "n/a",
  "title": "Mrs.",
  "sex": false,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "n/a",
  "mother": "n/a",
  "spouses": []
  },
  {
  "id": "1.06.c",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Beyenech",
  "lastName": "W/tsadiq",
  "nameMeaning": "n/a",
  "title": "Mrs.",
  "sex": false,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "n/a",
  "mother": "n/a",
  "spouses": []
  },
  {
  "id": "1.06.d",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Elfenesh",
  "lastName": "Mako",
  "nameMeaning": "n/a",
  "title": "Mrs.",
  "sex": false,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "n/a",
  "mother": "n/a",
  "spouses": []
  },
  {
  "id": "1.06.e",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Atsedech",
  "lastName": "Hunde",
  "nameMeaning": "n/a",
  "title": "Mrs.",
  "sex": false,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "n/a",
  "mother": "n/a",
  "spouses": []
  }
  ]
  },
  {
  "id": "1.07",
  "avatar": "https://res.cloudinary.com/dtknehocq/image/upload/v1707027879/Moti%20yidne%20family%20tree/chaltu_lhqgdi.jpg",
  "nickname": "n/a",
  "firstName": "Chaltu",
  "lastName": "Ayano",
  "nameMeaning": "n/a",
  "title": "Mrs.",
  "sex": false,
  "children": [
  {
  "id": "1.07.01",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Adugna",
  "lastName": "Regasa",
  "nameMeaning": "n/a",
  "title": "Mr.",
  "sex": true,
  "children": [],
  "birthDate": "1934",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "Regasa Balcha",
  "mother": "Chaltu Ayano",
  "spouses": []
  },
  {
  "id": "1.07.02",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Bizunesh",
  "lastName": "Regasa",
  "nameMeaning": "n/a",
  "title": "Ms.",
  "sex": false,
  "children": [],
  "birthDate": "1934",
  "deathDate": "5-05-1970",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "Regasa Balcha",
  "mother": "Chaltu Ayano",
  "spouses": []
  },
  {
  "id": "1.07.03",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Mekonen",
  "lastName": "Girma",
  "nameMeaning": "n/a",
  "title": "Mr.",
  "sex": true,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "n/a",
  "mother": "Chaltu Ayano",
  "spouses": []
  },
  {
  "id": "1.07.04",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Boge",
  "lastName": "Girma",
  "nameMeaning": "n/a",
  "title": "Mrs.",
  "sex": false,
  "children": [],
  "birthDate": "22-07-1959",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "n/a",
  "mother": "Chaltu Ayano",
  "spouses": []
  }
  ],
  "birthDate": "n/a",
  "deathDate": "23-01-2010",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "Ayano Benti",
  "mother": "Alemi Dori",
  "spouses": [
  {
  "id": "1.07.a",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Regasa",
  "lastName": "Balcha",
  "nameMeaning": "n/a",
  "title": "Mr.",
  "sex": true,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "n/a",
  "mother": "n/a",
  "spouses": []
  }
  ]
  },
  {
  "id": "1.08",
  "avatar": "https://res.cloudinary.com/dtknehocq/image/upload/v1707027885/Moti%20yidne%20family%20tree/IMG_20240203_151630_cw6wjl.jpg",
  "nickname": "n/a",
  "firstName": "Bekele",
  "lastName": "Ayano",
  "nameMeaning": "n/a",
  "title": "Mr.",
  "sex": true,
  "children": [
  {
  "id": "1.08.01",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Tadese",
  "lastName": "Bekele",
  "nameMeaning": "n/a",
  "title": "Mr.",
  "sex": true,
  "children": [
  {
  "id": "1.08.01.01",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Kidist",
  "lastName": "Tadese",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": false,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathcause": "n/a",
  "cementery": "n/a",
  "deathCause": "n/a",
  "history": "n/a",
  "father": "Tadese Bekele",
  "mother": "n/a",
  "spouses": []
  },
  {
  "id": "1.08.01.02",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Meron",
  "lastName": "Tadese",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": false,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathcause": "n/a",
  "cementery": "n/a",
  "deathCause": "n/a",
  "history": "n/a",
  "father": "Tadese Bekele",
  "mother": "n/a",
  "spouses": []
  }
  ],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathcause": "n/a",
  "cementery": "n/a",
  "deathCause": "n/a",
  "history": "n/a",
  "father": "Bekele Ayano",
  "mother": "Asegedech G/Mariam",
  "spouses": []
  },
  {
  "id": "1.08.02",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Tsehaye",
  "lastName": "Bekele",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": false,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathcause": "n/a",
  "cementery": "n/a",
  "deathCause": "n/a",
  "history": "n/a",
  "father": "Bekele Ayano",
  "mother": "Asegedech Haile G/Mariam",
  "spouses": []
  },
  {
  "id": "1.08.03",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Efrem",
  "lastName": "Bekele",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": true,
  "children": [
  {
  "id": "1.08.03.01",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Nebiyu",
  "lastName": "Efrem",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": true,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathcause": "n/a",
  "cementery": "n/a",
  "deathCause": "n/a",
  "history": "n/a",
  "father": "Efrem Bekele",
  "mother": "n/a",
  "spouses": []
  },
  {
  "id": "1.08.03.02",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Biruk",
  "lastName": "Efrem",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": true,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathcause": "n/a",
  "cementery": "n/a",
  "deathCause": "n/a",
  "history": "n/a",
  "father": "Efrem Bekele",
  "mother": "n/a",
  "spouses": []
  }
  ],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathcause": "n/a",
  "cementery": "n/a",
  "deathCause": "n/a",
  "history": "n/a",
  "father": "Bekele Ayano",
  "mother": "Asegedech G/Mariam",
  "spouses": []
  },
  {
  "id": "1.08.04",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Yewubdar",
  "lastName": "Bekele",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": false,
  "children": [
  {
  "id": "1.08.04.01",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Yosef",
  "lastName": "Wondimu",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": true,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathcause": "n/a",
  "cementery": "n/a",
  "deathCause": "n/a",
  "history": "n/a",
  "father": "Wondimu Desta",
  "mother": "Yewubdar Bekele",
  "spouses": []
  },
  {
  "id": "1.08.04.02",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Semira",
  "lastName": "Workeneh",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": false,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathcause": "n/a",
  "cementery": "n/a",
  "deathCause": "n/a",
  "history": "n/a",
  "father": "Workeneh Geneme",
  "mother": "Yewubdar Bekele",
  "spouses": []
  },
  {
  "id": "1.08.04.03",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Thomas",
  "lastName": "Tesfaye",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": true,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathcause": "n/a",
  "cementery": "n/a",
  "deathCause": "n/a",
  "history": "n/a",
  "father": "Tesfaye Bekele",
  "mother": "Yewubdar Bekele",
  "spouses": []
  },
  {
  "id": "1.08.04.04",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Bethelem",
  "lastName": "Tesfaye",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": false,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathcause": "n/a",
  "cementery": "n/a",
  "deathCause": "n/a",
  "history": "n/a",
  "father": "Tesfaye Bekele",
  "mother": "Yewubdar Bekele",
  "spouses": []
  },
  {
  "id": "1.08.04.05",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Beka",
  "lastName": "Feisa",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": true,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathcause": "n/a",
  "cementery": "n/a",
  "deathCause": "n/a",
  "history": "n/a",
  "father": "Feisa Gebretsadiq",
  "mother": "Yewubdar Bekele",
  "spouses": []
  },
  {
  "id": "1.08.04.06",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Dawit",
  "lastName": "Feisa",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": true,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathcause": "n/a",
  "cementery": "n/a",
  "deathCause": "n/a",
  "history": "n/a",
  "father": "Feisa Gebretsadiq",
  "mother": "Yewubdar Bekele",
  "spouses": []
  },
  {
  "id": "1.08.04.07",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Hawi",
  "lastName": "Feisa",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": false,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathcause": "n/a",
  "cementery": "n/a",
  "deathCause": "n/a",
  "history": "n/a",
  "father": "Feisa Gebretsadiq",
  "mother": "Yewubdar Bekele",
  "spouses": []
  },
  {
  "id": "1.08.04.08",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Eyael",
  "lastName": "Feisa",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": true,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathcause": "n/a",
  "cementery": "n/a",
  "deathCause": "n/a",
  "history": "n/a",
  "father": "Feisa Gebretsadiq",
  "mother": "Yewubdar Bekele",
  "spouses": []
  }
  ],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathcause": "n/a",
  "cementery": "n/a",
  "deathCause": "n/a",
  "history": "n/a",
  "father": "Bekele Ayano",
  "mother": "Asegedesch Haile G/Mariam",
  "spouses": []
  },
  {
  "id": "1.08.05",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Almaz",
  "lastName": "Bekele",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": false,
  "children": [
  {
  "id": "1.08.05.01",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Hiwot",
  "lastName": "Ayele",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": false,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathcause": "n/a",
  "cementery": "n/a",
  "deathCause": "n/a",
  "history": "n/a",
  "father": "Ayele Asefa",
  "mother": "Almaz Bekele",
  "spouses": []
  },
  {
  "id": "1.08.05.02",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Amanuel",
  "lastName": "Ayele",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": true,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathcause": "n/a",
  "cementery": "n/a",
  "deathCause": "n/a",
  "history": "n/a",
  "father": "Ayele Asefa",
  "mother": "Almaz Bekele",
  "spouses": []
  }
  ],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathcause": "n/a",
  "cementery": "n/a",
  "deathCause": "n/a",
  "history": "n/a",
  "father": "Bekele Ayano",
  "mother": "n/a",
  "spouses": []
  },
  {
  "id": "1.08.06",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Wederyelesh",
  "lastName": "Bekele",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": false,
  "children": [
  {
  "id": "1.08.06.01",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Berhanu",
  "lastName": "Bekele",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": true,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathcause": "n/a",
  "cementery": "n/a",
  "deathCause": "n/a",
  "history": "n/a",
  "father": "Bekele Wami",
  "mother": "Wederyelesh Bekele",
  "spouses": []
  },
  {
  "id": "1.08.06.02",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Dibora",
  "lastName": "Bekele",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": false,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathcause": "n/a",
  "cementery": "n/a",
  "deathCause": "n/a",
  "history": "n/a",
  "father": "Bekele Wami",
  "mother": "Wederyelesh Bekele",
  "spouses": []
  }
  ],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathcause": "n/a",
  "cementery": "n/a",
  "deathCause": "n/a",
  "history": "n/a",
  "father": "Bekele Ayano",
  "mother": "Asegedesch Haile G/Mariam",
  "spouses": []
  },
  {
  "id": "1.08.07",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Manyaheleshal",
  "lastName": "Bekele",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": false,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathcause": "n/a",
  "cementery": "n/a",
  "deathCause": "n/a",
  "history": "n/a",
  "father": "Bekele Ayano",
  "mother": "Asegedesch Haile G/Mariam",
  "spouses": []
  },
  {
  "id": "1.08.08",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Yonas",
  "lastName": "Bekele",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": true,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathcause": "n/a",
  "cementery": "n/a",
  "deathCause": "n/a",
  "history": "n/a",
  "father": "Bekele Ayano",
  "mother": "Asegedesch Haile G/Mariam",
  "spouses": []
  },
  {
  "id": "1.08.09",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Qejele",
  "lastName": "Bekele",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": true,
  "children": [
  {
  "id": "1.08.09.01",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Yohana",
  "lastName": "Qejele",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": false,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathcause": "n/a",
  "cementery": "n/a",
  "deathCause": "n/a",
  "history": "n/a",
  "father": "Qejele Bekele",
  "mother": "n/a",
  "spouses": []
  },
  {
  "id": "1.08.09.02",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Yabtse",
  "lastName": "Qejele",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": true,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathcause": "n/a",
  "cementery": "n/a",
  "deathCause": "n/a",
  "history": "n/a",
  "father": "Qejele Bekele",
  "mother": "n/a",
  "spouses": []
  },
  {
  "id": "1.08.09.03",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Perasim",
  "lastName": "Qejele",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": false,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathcause": "n/a",
  "cementery": "n/a",
  "deathCause": "n/a",
  "history": "n/a",
  "father": "Qejele Bekele",
  "mother": "n/a",
  "spouses": []
  }
  ],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathcause": "n/a",
  "cementery": "n/a",
  "deathCause": "n/a",
  "history": "n/a",
  "father": "Bekele Ayano",
  "mother": "Asegedesch Haile G/Mariam",
  "spouses": []
  },
  {
  "id": "1.08.10",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Shame",
  "lastName": "Bekele",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": true,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathcause": "n/a",
  "cementery": "n/a",
  "deathCause": "n/a",
  "history": "n/a",
  "father": "Bekele Ayano",
  "mother": "Asegedesch Haile G/Mariam",
  "spouses": []
  },
  {
  "id": "1.08.11",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Bizunesh",
  "lastName": "Bekele",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": false,
  "children": [
  {
  "id": "1.08.11.01",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Lema",
  "lastName": "Bekele",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": true,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathcause": "n/a",
  "cementery": "n/a",
  "deathCause": "n/a",
  "history": "n/a",
  "father": "n/a",
  "mother": "Bizunesh Bekele",
  "spouses": []
  },
  {
  "id": "1.08.11.02",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Bizuayehu",
  "lastName": "Bekele",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": true,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathcause": "n/a",
  "cementery": "n/a",
  "deathCause": "n/a",
  "history": "n/a",
  "father": "n/a",
  "mother": "Bizunesh Bekele",
  "spouses": []
  },
  {
  "id": "1.08.11.03",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Tigist",
  "lastName": "Lema",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": false,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathcause": "n/a",
  "cementery": "n/a",
  "deathCause": "n/a",
  "history": "n/a",
  "father": "n/a",
  "mother": "Bizunesh Bekele",
  "spouses": []
  },
  {
  "id": "1.08.11.04",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Adanech",
  "lastName": "Lema",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": false,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathcause": "n/a",
  "cementery": "n/a",
  "deathCause": "n/a",
  "history": "n/a",
  "father": "n/a",
  "mother": "Bizunesh Bekele",
  "spouses": []
  }
  ],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathcause": "n/a",
  "cementery": "n/a",
  "deathCause": "n/a",
  "history": "n/a",
  "father": "Bekele Ayano",
  "mother": "Asegedesch Haile G/Mariam",
  "spouses": []
  }
  ],
  "birthDate": "1915",
  "deathDate": "1976",
  "deathcause": "n/a",
  "cementery": "Asko Gebriel",
  "deathCause": "Disease",
  "history": "n/a",
  "father": "Ayano Benti",
  "mother": "Alemi Dori",
  "spouses": [
  {
  "id": "1.08.a",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Asegedech ",
  "lastName": "Haile G/Mariam",
  "nameMeaning": "n/a",
  "title": "Mr.",
  "sex": true,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a", 
  "cementery": "n/a",
  "deathCause": "n/a",
  "history": "n/a",
  "father": "n/a",
  "mother": "n/a",
  "spouses": []
  }
  ]
  },
  {
  "id": "1.09",
  "avatar": "https://res.cloudinary.com/dtknehocq/image/upload/v1707027880/Moti%20yidne%20family%20tree/like_brvowp.jpg",
  "nickname": "n/a",
  "firstName": "Leke",
  "lastName": "Ayano",
  "nameMeaning": "n/a",
  "title": "Mrs.",
  "sex": false,
  "children": [
  {
  "id": "1.09.01",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Tamrat",
  "lastName": "Asefa",
  "nameMeaning": "n/a",
  "title": "Mr.",
  "sex": true,
  "children": [
  {
  "id": "1.09.01.01",
  "avatar": "n/a",
  "nickname": "Bita",
  "firstName": "Yamlak",
  "lastName": "Tamrat",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": false,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "Tamrat Asefa ",
  "mother": "n/a",
  "spouses": []
  },
  {
  "id": "1.09.02",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Aberash",
  "lastName": "Asefa",
  "nameMeaning": "n/a",
  "title": "Mrs.",
  "sex": false,
  "children": [],
  "birthDate": "1955",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "Asefa Werdefa",
  "mother": "Leke Ayano",
  "spouses": []
  }
  ],
  "birthDate": "12-04-1953",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "Asefa Werdefa",
  "mother": "Leke Ayano",
  "spouses": []
  },
  {
  "id": "1.09.02",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Aberash",
  "lastName": "Asefa",
  "nameMeaning": "n/a",
  "title": "Mrs.",
  "sex": false,
  "children": [],
  "birthDate": "1955",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "Asefa Werdefa",
  "mother": "Leke Ayano",
  "spouses": []
  },
  {
  "id": "1.09.03",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Woineshet",
  "lastName": "Asefa",
  "nameMeaning": "n/a",
  "title": "Mrs.",
  "sex": false,
  "children": [],
  "birthDate": "1955",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "Asefa Werdefa",
  "mother": "Leke Ayano",
  "spouses": []
  }
  ],
  "birthDate": "06-02-1991",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "Ayano Benti",
  "mother": "Alemi Dori",
  "spouses": [
  {
  "id": "1.09.a",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Asefa",
  "lastName": "Werdefa",
  "nameMeaning": "n/a",
  "title": "Mr.",
  "sex": true,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "n/a",
  "mother": "n/a",
  "spouses": []
  }
  ]
  },
  {
  "id": "1.10",
  "avatar": "https://res.cloudinary.com/dtknehocq/image/upload/v1707027883/Moti%20yidne%20family%20tree/IMG_20240203_151455_yaaxlb.jpg",
  "nickname": "n/a",
  "firstName": "Teka",
  "lastName": "Tula",
  "nameMeaning": "n/a",
  "title": "Colonel",
  "sex": true,
  "children": [
  {
  "id": "1.10.01",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Samson",
  "lastName": "Teka",
  "nameMeaning": "n/a",
  "title": "Dr.",
  "sex": true,
  "children": [
  {
  "id": "1.10.01.01",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Natnael",
  "lastName": "Samson",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": true,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "Samson Teka",
  "mother": "n/a",
  "spouses": []
  },
  {
  "id": "1.10.01.02",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Abigail",
  "lastName": "Samson",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": false,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "Samson Teka",
  "mother": "n/a",
  "spouses": []
  }
  ],
  "birthDate": "28-06-1955",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "Teka Tula",
  "mother": "Bere Sahle",
  "spouses": []
  },
  {
  "id": "1.10.02",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Etsegenet",
  "lastName": "Teka",
  "nameMeaning": "n/a",
  "title": "Mrs.",
  "sex": false,
  "children": [
  {
  "id": "1.10.02.01",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Zemichael",
  "lastName": "Mulugeta",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": true,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "n/a",
  "mother": "Etsegenet Teka",
  "spouses": []
  }
  ],
  "birthDate": "1960",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "Teka Tula",
  "mother": "Bere Sahle",
  "spouses": []
  },
  {
  "id": "1.10.03",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Ensermu",
  "lastName": "Teka",
  "nameMeaning": "n/a",
  "title": "Mr.",
  "sex": true,
  "children": [
  {
  "id": "1.10.03.01",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Meron",
  "lastName": "Ensermu",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": false,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "Ensermu Teka",
  "mother": "n/a",
  "spouses": []
  },
  {
  "id": "1.10.03.02",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Lensa",
  "lastName": "Ensermu",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": false,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "Ensermu Teka",
  "mother": "n/a",
  "spouses": []
  }
  ],
  "birthDate": "1962",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "Teka Tula",
  "mother": "Bere Sahle",
  "spouses": []
  },
  {
  "id": "1.10.04",
  "avatar": "n/a",
  "nickname": "Dosho",
  "firstName": "Yada",
  "lastName": "Teka",
  "nameMeaning": "n/a",
  "title": "Mrs.",
  "sex": false,
  "children": [
  {
  "id": "1.10.04.01",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Hawi",
  "lastName": "Teka",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": false,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "n/a",
  "mother": "Yada Teka",
  "spouses": []
  },
  {
  "id": "1.10.04.02",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Haika",
  "lastName": "Teka",
  "nameMeaning": "n/a",
  "title": "n/a",
  "sex": false,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "n/a",
  "mother": "Yada Teka",
  "spouses": []
  }
  ],
  "birthDate": "07-03-1966",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "Teka Tula",
  "mother": "Bere Sahle",
  "spouses": []
  },
  {
  "id": "1.10.05",
  "avatar": "n/a",
  "nickname": "Fita",
  "firstName": "Yada",
  "lastName": "Teka",
  "nameMeaning": "n/a",
  "title": "Mr.",
  "sex": true,
  "children": [],
  "birthDate": "01-11-1967",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "Teka Tula",
  "mother": "Bere Sahle",
  "spouses": []
  }
  ],
  "birthDate": "1928",
  "deathDate": "28-01-1991",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "Tula Benti",
  "mother": "Alemi Dori",
  "spouses": [
  {
  "id": "1.10.a",
  "avatar": "n/a",
  "nickname": "n/a",
  "firstName": "Bere",
  "lastName": "Sahle",
  "nameMeaning": "n/a",
  "title": "Mrs.",
  "sex": false,
  "children": [],
  "birthDate": "n/a",
  "deathDate": "n/a",
  "deathCause": "n/a",
  "cementery": "n/a",
  "history": "n/a",
  "father": "n/a",
  "mother": "n/a",
  "spouses": []
  }
  ]
  }
  ]
  }