import React from "react";
import "./Header.css";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Person, Person2 } from "@mui/icons-material";
function Header({ genealogy, handleBackPressed }) {
  return (
    <div className="header-nav">
      { genealogy.length>1?<div className="back-arrow" onClick={handleBackPressed}><ArrowBackIcon/></div> :<Person2/>}
      {genealogy.map((person, index) => (
        <p>
          {index !== 0 ? "/  " : null}
          {person.name}
        </p>
      ))}
    </div>
  );
}

export default Header;
